.list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

.hidden {
	display: none !important;
	pointer-events: none !important;
}

.no-action {
	pointer-events: none !important;
}

.no-select {
	user-select: none !important;
}

.full-size {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.empty-container {
	height: 0 !important;
	min-height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	@extend .no-action;

	&::before, &::after {
		display: none;
	}
}

$ease-power: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

.ease-power {
	transition-timing-function: $ease-power !important;
}

.ease-in-out-quad {
	transition-timing-function: $ease-in-out-quad !important;
}

.ease-in-out-cubic {
	transition-timing-function: $ease-in-out-cubic !important;
}

.clearfix {
	@include clearfix();
}
