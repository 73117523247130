$font: "franklin-gothic-urw", sans-serif;
$font-amatic: "Amatic SC", cursive;

// Headings.
// -----------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2rem 0 1rem;
  font-weight: 700;
  line-height: 1.3;
}

h1, .h1 {
  font-size: em(22);
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: 1px;

  @include media($desk) {
    font-size: em(42);
    line-height: 1.2;
  }
}

h2, .h2 {
  font-size: em(18);
  line-height: 1.2;
  font-weight: 700;

  @include media($desk) {
    font-size: em(26);
    letter-spacing: 1px;
  }
}

h3, .h3 {
  font-size: em(14);
  font-weight: 700;
  line-height: 1.28;

  @include media($desk) {
    font-size: em(16);
  }
}

h4, .h4 {
  font-size: em(14);
  font-weight: 700;
  line-height: 1.28;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include media($desk) {
    font-size: em(16);
  }
}

.italic {
  font-style: italic;
}

.page-title {
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 1rem;

  @include media($desk) {
    letter-spacing: 1px;
  }


  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border-radius: 50%;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.subtitle {
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5;
  font-weight: 500;

  @include media($tab) {
    font-size: em(18);
  }
}

.date {
  letter-spacing: 1px;
  color: $grey;
  text-transform: uppercase;
  font-weight: 500;
}

.testimonial {
  font-size: em(20);
  line-height: 1.3;

  @include media($desk) {
    font-size: em(28);
  }
}

.link-underline {
  border-bottom: 1px solid $blue;
}

p {
  font-size: 1em;

  &.bold {
    font-weight: 500;
  }

  a {
    @extend .link-underline;
  }
}

.reference {
  font-size: 0.7em;
  color: $blue;
  display: block;
  margin-top: 1em;
}

.lead {
  font-size: em(18);
  line-height: 1.625;

  @include media($desk) {
    font-size: em(24);
    line-height: 1.5;
  }
}

.large-paragraph {
  font-size: em(16);
  font-weight: 500;
  line-height: 1.6;

  @include media($desk) {
    line-height: 1.36;
    font-size: em(22);
  }
}

.small {
  font-size: em(14);
  line-height: 1.5;
}

.smallest {
  font-size: em(12);
  line-height: 1.5;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
  color: inherit;

  &:hover {
    color: $blue;
  }
}

a.download-file {
  color: $blue;

  &:hover {
    color: $blue-2;
  }
}

.large-link-base {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  line-height: 1.3;
  display: inline-block;
  padding-bottom: 0.2em;
  font-size: 0.9em;

  i {
    font-size: 1.8em;
    vertical-align: middle;
  }
}

.large-link {
  @extend .large-link-base;

  &:hover {
    color: white;

    &::after {
      transform: scaleX(1.1);
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    display: block;
    background: white;
    transition: transform 0.3s ease;
  }
}

.large-link--blue {
  @extend .large-link;

  // color: $blue
  &::after {
    background: $blue;
  }
}

.large-link--blue-all {
  @extend .large-link--blue;

  &:hover {
    color: $blue;
  }
}

.large-link-icon {
  @extend .large-link-base;
}

.blue-bar-link {
  display: block;
  background: $blue;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  letter-spacing: 1px;
  color: white;
  font-size: 0.8em;
  align-items: center;

  i {
    font-size: 1.5em;
    margin-left: 1rem;
  }

  &:hover {
    color: white;
    background: $blue-hover;
  }
}

// resetting button so we can use it more
button {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.btn, .button {
  background: $blue;
  border: none;
  color: white;
  font-weight: 500 !important;
  display: inline-block;
  padding: 1rem 1.5rem;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  appearance: none;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  color: white;
  border-radius: 3px;
  margin-top: 10px;
  font-size: 0.8em;
  user-select: none;
  cursor: pointer;

  .wysiwyg & {
    color: white !important;
  }

  &:hover {
    color: white;
    background: $blue-2;
  }

  i {
    font-size: 1.4em;
  }

  &--green {
    background: $green;

    &:hover {
      background: $green-hover;
    }
  }

  &--large {
    padding: 18px 1.5rem;
    font-size: 1em;
    font-weight: 700;
    margin-top: 30px;
    letter-spacing: 1px;
  }

  &--full {
    display: block;
    width: 100%;
  }
}

.button {
  &.purple {
    background-color: #A1519F;
    color: white;
    
    &:hover {
      background-color: #894687;
      color: white;
    }
  }
  
  &.green {
    background-color: $green;
    color: white;
    
    &:hover {
      background-color: $green;
      color: white;
    }
  }
  
  &.white {
    background-color: white;
    color: $blue !important;
    
    &:hover {
      background-color: #eee;
      color: $blue !important;
    }
  }
  &.blue {
    background-color: #1e73be;
    color: #fff !important;
    
    &:hover {
      background-color: #0d5991;
      color: #fff !important;
    }
  }
  
  &.bigger {
    font-size: 16px;
    padding: 1.563rem 1.5rem;
    
    &.inverted {
      padding: 1.438rem 1.375rem;
    }
  }
  
  &.inverted {
    border: 2px solid $blue;
    background-color: transparent;
    color: $blue !important;
    padding: 0.875rem 1.344rem 0.875rem 1.344rem;
    
    &:hover {
      background-color: $blue;
      color: white !important;
      border-color: $blue;
    }
    
    &.white {
      border-color: white;
      color: white !important;
      background-color: transparent;
      
      &:hover {
        background-color: white;
        color: $blue !important;
        border-color: white;
      }
    }
    
    &.purple {
      border: 2px solid #A1519F;
      background-color: transparent;
      color: #A1519F !important;
      
      &:hover {
        background-color: #A1519F;
        color: white !important;
        border-color: #A1519F;
      }
    }
    
    &.green {
      border: 2px solid $green;
      background-color: transparent;
      color: $green !important;
      
      &:hover {
        background-color: $green;
        color: white !important;
        border-color: $green;
      }
    }
  }
}

.btn-outline {
  border: 1px solid $green;
  color: $green;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 500;
  font-size: em(14);

  &:focus,
  &:hover {
    background: $green;
    color: white;
  }
}

.link-green {
  text-transform: uppercase;
  color: $green-hover;
  font-weight: 500;
  font-size: em(14);

  &:hover {
    color: $green;
  }
}

.caps {
  text-transform: uppercase;
}

.material-icons {
  vertical-align: middle;
}

.material-icons--blue,
.icon-blue {
  color: $blue;
}

.material-icons--white,
.icon-white {
  color: white;
}

.material-icons--circle,
.icon-circle {
  border-radius: 50%;
  border: 1px solid $blue;
  padding: 0.3rem;
  margin-right: 0.5rem;
}

.callout {
  @extend .lead;

  margin-left: 10%;
  margin-right: 10%;
  padding-left: 2rem;
  border-left: 5px solid $blue;

  @include media($mobileOnly) {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.small {
  font-size: 85%;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-bottom: 1px solid $offwhite;
}

.header-reset {
  h1, h2, h3, h4 {
    @extend .subtitle;
  }
}

.no-top-margin {
  margin-top: 0;
}

.font-amatic {
  font-family: $font-amatic;
}

.text-uppercase {
  text-transform: uppercase;
}