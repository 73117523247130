.panic-bar-top {
  padding: 5px 12px;
  background: $green;
  color: #fff;
  @include media($desk) {
  text-align: right;
  }
}


.panic-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: rgba($offwhite,.5);
  opacity: 0;
  transition: opacity .25s, height 0 .5s;
  &.visible {
    opacity: 1;
    height: 100%;
    transition: opacity .5s 2s, height 0 0;
  }
  .inner {
    width: 90%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 3px solid $red;//$green;
    padding: 30px;
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $red;
    }
    p {
      margin: 0;
    }
    p + p {
      margin-top: 1em;
    }
  }
}


button.panic {
  color: #fff;
  background: $red;//$green;
  font-size: 2rem;
  border-radius: 100%;
  border: none;
  position: absolute;
  top: .5rem;
  right: .5rem;
  &.footer {
    position: fixed;
    font-size: 14px;
    border-radius: 30px;
    font-weight: bold;
    top: auto;
    bottom: 1.75rem;
    right: 6rem;
    z-index: 1000;
    padding: 6px 12px 4px;
  }
}
