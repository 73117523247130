body.page-template-pathfinder-welcome,
body.page-template-pathfinder-step1,
body.page-template-pathfinder-step2,
body.page-template-pathfinder-step3,
body.page-template-pathfinder-step4,
body.page-template-pathfinder-general{
    
    .site-header-internal-nav {
        display: none;
    }
}

/*common styles*/
.main-content.pathfinder {
    padding-top: 0;
    
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        margin-bottom: 10px;
    }
    
    h4 {
        text-transform: uppercase;
    }
    
    // taken from .eservices-header__info p
    p:not(.small) {
        margin: 0;
        margin-bottom: 10px;
        font-size: 16px;
  
        @include media($desk) {
          font-size: 20px;
        }
    }
    
    .wysiwyg {
        
        p:not(.small) {
            font-size: 16px;
        }
        
        & > *:first-child {
            margin-top: 0;
        }
        
        & > *:last-child {
            margin-bottom: 0;
        }
        
        h4 {
            font-size: 1em;
            font-weight: 500;
        }
    }
    
    .link {
        @extend .large-link;
        color: #B0B0A9;
        
        &:after {
            background: #B0B0A9;
        }
        
        &:hover {
            color: #B0B0A9;
        }
        
        &:focus, &:active {
            outline: none;
        }
        
        &.blue {
            color: $blue;
            
            &:after {
                background: $blue;
            }
            
            &:hover {
                color: #2D96AF;
                
                &:after {
                    background-color: #2D96AF;
                }
            }
            
        }
        
        &.dashed {
            &:after {
                border-bottom: 2px dashed #B0B0A9;
                background: none;
            }
            
            &.blue {
                &:after {
                    border-color: $blue;
                }
                
                &:hover {
                    
                    &:after {
                        border-color: #2D96AF;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    
    .button {
        margin-top: 0;
        
        &.medium {
            font-size: 16px;
            padding: 1.563rem 1.5rem;
            
            @include media($tabAndLess){
                padding: 1.188rem 1.5rem;
            }

            &.inverted {
                padding: 1.375rem 1.5rem;
                border-width: 3px;
                
                @include media($tabAndLess){
                    padding: 1rem 1.5rem;
                }
            }
        }
        
        &.large {
            font-size: 20px;
            padding: 2.25rem 1.5rem;
            @include media($tabAndLess){
                font-size: 16px;
                padding: 1.563rem 1.5rem;
            }
            
            &.inverted {
                padding: 2.063rem 1.5rem;
                border-width: 3px;
                
                @include media($tabAndLess){
                   padding: 1.375rem 1.5rem;
                }
            }
        }
        
        &.disable-toggle { // use this class to make transition smooth between disabled and non-disabled
            border: 2px solid $blue;
            padding: 0.875rem 1.344rem 0.875rem 1.344rem;
            
            &.medium {
                padding: 1.375rem 1.5rem;
                border-width: 3px;
                
                @include media($tabAndLess){
                    padding: 1rem 1.5rem;
                }
            }
            
            &.large {
                padding: 2.063rem 1.5rem;
                border-width: 3px;
                
                @include media($tabAndLess){
                   padding: 1.375rem 1.5rem;
                }
            }
        }
        
        &.disabled {
            border-color: #C1C1C1;
            pointer-events: none;
            background-color: transparent;
            color: #E0E0E0;
        }
        
        &.download-pdf {
            i {
                margin-right: 7px;
            }
        }
    }
    
    &.pathfinder-welcome {
        background-image: url(../img/pathfinder/bgs/bg-blue1.svg);
        background-repeat: no-repeat;
        background-size: cover;
        
        * {
            color: white;
        }
    }
    
    > section {
        margin-top: 4rem;
        margin-bottom: 4rem;
        
        .wysiwyg {
            margin: 0;
        }
        
        &.selection-summary {
            margin: 0;
        }
        
        &.columns {
            .column {
                @include media($mobileOnly){
                    border-bottom: none;
                    text-align: center;
                }
                
                &:last-child:not(:first-child) {
                    .column__inner {
                        @include media($ptab){
                            text-align: right;
                        }
                    }
                }
            }
            
            .columns__inner {
                align-items: center;
            }
        }
        
        &.general-support-cta {
            
            .btn-container {
                text-align: center;
                margin-bottom: 45px !important;
                
                + .wysiwyg{
                    text-align: center;
                }
            }
            
            

        }
    }
    
    &:not(.pathfinder-welcome){
        > section {

            &.intro {
                
                .copy {
                    position: relative;
                    
                    @include media($ptabAndLess) {
                        text-align: center;
                    }
                    
                    @include media($tab) {
                        width: 75%;
                        float: left;
                        padding-right: 30px;
                    }
                    
                    @media(min-width: 769px) and (max-width: 1024px) {
                        padding-left: 35px;
                    }
                    
                    @media(min-width: 1025px) and (max-width: 1165px) {
                        padding-left: 65px;
                    }
                        
                    > i {
                        font-size: 120px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: -1;
                        top: -40px;
                        color: #F3F3F0;
                        position: absolute;
                        font-weight: bold;
                        
                        @include media($tab) {
                            font-size: 55px;
                            left: 6px;
                            top: -9px;
                        }
                        
                        @media(min-width: 1025px) and (max-width: 1165px) {
                            font-size: 70px;
                            left: 25px;
                            top: -6px;
                        }
                        
                        @media(min-width: 1166px){
                            font-size: 70px;
                            left: -35px;
                            top: -6px;
                        }
                        
                        &.material-icons-list_alt {
                            @media(min-width: 1166px){
                                font-size: 56px;
                                top: 0;
                            }
                        }
                    }
                    
                    > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            
            &.columns.col-count-2 {
                .wysiwyg {
                    margin: 0;
                }
            }
            
            &.privacy {
                margin: 0;
                padding: 1.3rem 0 2.2rem;
                background-color: #F3F3F0;
                background-image: url(../img/pathfinder/bgs/bg-sand1.svg);
                background-repeat: no-repeat;
                background-position: bottom left;
                
                /*.column {
                    &:first-child{
                        .column__inner {
                            .wysiwyg {
                                @include media($mobileOnly){
                                    display: inline-block;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
                
                .wysiwyg {
                    margin: 0;
                }*/
                .column {
                    &:first-child {
                        @include media($tab) {
                            width: 70%;
                        }
                    }
                    
                    &:last-child {
                        @include media($tab) {
                            width: 30%;
                        }
                        
                        .column__inner {
                            text-align: center;
                        }
                    }
                }
                
            }
            
            &.stories {
                margin-bottom: 26px;
                
                @include media($mobileOnly){
                    margin-bottom: 15px;
                }
                .header {
                    margin-bottom: 20px;
                    h2 {
                        margin-bottom: 0;
                    }
                }
                
                .stories-content {
                    padding: 0;
                    
                    .story-cards__container {
                        
                        @include media($ptab){
                            padding: 0;
                        }
                    }
                    
                    .slide-container {
                        padding: 0;
                        
                        @include media($ptab){
                            transform: none !important;
                        }
                    }
                    
                    .slider__pagination {
                        display: none;
                    }
                    
                    .slider__pagers {
                        @include media($ptab){
                            display: none;
                        }
                    }
                    
                    &.count {
                        &-1 {
                            .story-card {
                                width: 100%;
                                
                                @include media($ptab){
                                    width: 100% !important;
                                }
                            }
                        }
                        
                        &-2 {
                            .story-card {
                                width: 100%;
                                
                                @include media($ptab){
                                    width: calc(50% - 13px) !important;
                                }
                            }
                        }
                        
                        &-3 {
                            .story-card {
                                width: 100%;
                                
                                @include media($ptab){
                                    width: calc(33.3333% - 17px) !important;
                                }
                            }
                        }
                    }
                    .story-card {
                        
                        @include media($ptab){
                            margin-right: 0 !important;
                        }
                        
                        & + .story-card {
                            @include media($ptab){
                                margin-left: 26px;
                            }
                            
                        }
                        
                        .story-card__description {
                            font-size: 16px;
                            margin: 1em 0;
                        }
                    }
                }
            }
            
            &.static-blocks {
                
                &:not(:first-child){
                    margin-top: 26px;
                    
                    @include media($mobileOnly){
                        margin-top: 15px;
                    }
                }
                
            }
            
            &.end {
                text-align: center;
                margin: 0;
                margin-bottom: 45px;
                
                h2 {
                    margin-bottom: 20px;
                    font-size: 23px;
                    padding-top: 35px;
                    border-top: 2px solid #CBCBCB;
                }
            }
        }
    }
    
    .expanders {
        .expander__trigger {
            position: relative;
            padding-right: 35px;
                
            .expander__state {
                position: absolute;
                top: -6px;
                right: 0;
                color: $green;
            }
        }
        
    }
    
    //wp video player
    
    .mejs-overlay {
        background: rgba(0,0,0,0.7);
        
        .mejs-overlay-button {
            background-image: url(../img/pathfinder/icons/icon-play.png);
            background-size: cover;
            background-position: center;
        }
        
        &:hover {
            .mejs-overlay-button {
                background-position: center;
            }
        }
    }
}

.pathfinder-header {
    background: #3DB0C7;
    padding: 18px 60px 18px 40px;
    
    @include media($ptabAndLess) {
        padding: 24px 10px 20px 40px;
    }
            
    h1 {
        font-family: $font-amatic;
        margin: 0;
        font-size: 30px;
        line-height: 1.06;
        margin-bottom: 6px;
    }
    
    p {
        margin: 0;
        font-size: 15px;
        line-height: 1.13;
        max-width: 190px;
    }
    
    * {
        color: white; 
    }
    
    img {
        width: 74px;
        float: left;
        margin-right: 8px;
        
        @include media($ptabAndLess) {
            width: 46px;
        }
    }
    
    > div {
        float: right;
        
        div {
            float: left;
            
            @include media($ptabAndLess) {
                display: none; 
            }
        }
    }
}

.pathfinder-nav {
    background-color: #F3F3F0;
    
    .container {
        @include media($ptabAndLess) {
            padding: 0;
        }
    }
    ul {
        list-style: none;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        
        @include media($ptabAndLess) {
            padding: 9px;
            text-align: center;
        }
        
        @include media($tab){
            padding-left: 0;
            padding-right: 0;
            @include clearfix(); 
        }
                
        li {
            float: left;
            
            @include media($ptabAndLess) {
                display: inline-block;
                float: none;
            }
            
            &:not(:last-child){
                margin-right: 14px;
                
                @include media($ptabAndLess) {
                    margin-right: 3px;
                }
                
                &:after {
                    @include material-icon('\e5cc');
                    color: #CACABB;
                    font-size: 22px;
                    margin-left: 14px;
                    
                    @include media($ptabAndLess) {
                        margin-left: 3px;
                    }
                }
            }
            
            i {
                color: $green;
                top: -1px;
                position: relative;
                font-size: 20px;
                font-weight: bold;
            }
            
            a {
                color: #3E4643;
                font-size: 16px;
                line-height: 1.1875;
            }
            
            .desk {
                @include media($ptabAndLess) {
                    display: none;
                }
            }
            
            .mobile {
                width: 27px;
                height: 27px;
                border-radius: 50%;
                background-color: $green;
                color: white;
                font-weight: 700;
                display: inline-block;
                line-height: 27px;
                text-align: center;
                
                i {
                    color: white;
                    margin: 0;
                    font-size: 18px;
                }
                
                @include media($tab) {
                    display: none;
                }
            }
        }
    }
}

body.page-template-pathfinder-welcome .pathfinder-nav li:first-child,
body.page-template-pathfinder-step1 .pathfinder-nav li:nth-child(2),
body.page-template-pathfinder-step2 .pathfinder-nav li:nth-child(3),
body.page-template-pathfinder-step3 .pathfinder-nav li:nth-child(4),
body.page-template-pathfinder-step4 .pathfinder-nav li:nth-child(5)
{
    a { font-weight: 700; }
    
    ~ li {
        a { color: #797D7C; pointer-events: none; }
        i { color: #CACABB; }
        
        .mobile {
            background-color: #CACABB;
        }
    }
}
body.page-template-pathfinder-general .pathfinder-nav li {
    a { color: #797D7C; pointer-events: none; }
    i { color: #CACABB; }
    
    .mobile {
        background-color: #CACABB;
        
        i {
            color: white;
        }
    }
    
    &:first-child {
        a {
            pointer-events: unset;
        }
    }
}

.pathfinder-modal {
    @extend .emergency-nav;
    
    &__overlay {
        @extend .emergency-nav__overlay;
        
        .pathfinder-modal-open & {
            opacity: 1;
            pointer-events: auto;
        }
    }
    &__container {
        @extend .emergency-nav__container;
        
        .pathfinder-modal-open & {
            transform: translate(-50%, 0);
        }
    }
    &__header {
        @extend .emergency-nav__header;
        
        h2 {
            margin: 0 !important;
            font-weight: 500;
        }
    }
    &__close {
        @extend .emergency-nav__close;
    }
    &__body {
        @extend .emergency-nav__body;
        border-radius: 0;
        background-image: url(../img/pathfinder/bgs/bg-sand3.svg);
        background-repeat: no-repeat;
        background-position: top left;
    }
    &__inner {
        @extend .emergency-nav__inner;
    }
    &__footer {
        background-color: white;
        border-top: 3px solid $blue;
        padding: 20px 30px;
        position: relative;
        overflow: hidden;
        background-image: url(../img/pathfinder/bgs/bg-sand4.svg);
        background-repeat: no-repeat;
        background-position: bottom right;
        border-radius: 0 0 5px 5px;
        
        @include media($desk){
            padding: 35px;
        }
        
        .pathfinder-modal__inner {
            text-align: center;
        }
    }
    
    &.check-in {
        .wysiwyg {
            p {
                font-size: 18px;
                
                @include media($desk){
                    font-size: 20px;
                }
            }
            
            h2 {
                font-size: 30px;
                
                @include media($desk){
                    font-size: 39px;
                }
            }
            
        }
        
    }
    
    &.for-urgent {
        .pathfinder-modal__footer:not(.urgent-footer) {
            display: none;
        }
        
        .urgent-btn-hide {
            @include media($desk){
                min-width: 280px;
            }
            
        }
    }
    
    &.for-questionnaire {
        .pathfinder-modal__footer:not(.questionnaire-footer) {
            display: none;
        }
        
        .questionnaire-footer {
            input {
                position: relative;
                top: -2px;
                margin-right: 8px;
            }
            label {
                font-size: 20px;
                margin-right: 22px;
            }
            
            .button {
                @include media($mobileOnly){
                    margin: 15px 0;
                }
            }
        }
    }
    
    &.issue-tip {
        .pathfinder-modal__container {
            min-height: 0;
        }
        .pathfinder-modal__body {
            border-radius: 0 0 5px 5px;
            background-image: none;
        }
        
        .pathfinder-modal__header {
            padding-top: 50px;
        }
    }
    
    &.before-you-go {
        .pathfinder-modal__body,
        .pathfinder-modal__header {
            text-align: center;
        }
        
        .pathfinder-modal__body,
        .pathfinder-modal__footer{
            background-image: none;
        }
        
        .pathfinder-modal__footer{
            padding: 10px 30px;
                
            @include media($desk){
                padding: 25px 35px;
            }
            
            .pathfinder-modal__inner {
                padding: 0;
            }
            .button {
                margin: 10px;
            }
        }
        
        .text-center {
            text-align: center;
            margin-top: 35px;
        }
    }
}

.selection-summary {
    padding-top: 10px;
    padding-bottom: 15px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.1);
    
    .container {
        position: relative;
        
        &.selection-summary-container {
            display: none;
            padding-top: 40px;
            padding-bottom: 28px;
            
            .pathfinder-step4 & {
                padding-top: 10px;
            }
        }
    }
    
    .header {
        position: relative;
        padding-right: 190px;
        margin-bottom: 30px;
        
        @include media($ptabAndLess){
            padding-right: 0;
            text-align: center;
        }
    }
    
    .selections {
        padding-right: 190px;
        
        @include media($ptabAndLess){
            padding-right: 0;
        }
    }
    
    .container .change-selections {
        color: #C8A3C7;
        
        &:after {
            background: #C8A3C7 !important;
        }
        
        &:hover {
            color: #C8A3C7;
        }
        
        @include media($tab){
            position: absolute;
            top: 6px;
            right: 2em;
        }
    }
    
    .change-selections-wrapper {
        text-align: center;
        
        @include media($ptabAndLess){
            margin-top: 40px;
        }
    }
    
    .link.review-selections {
        font-size: 12px;
    }
}

.symptom-selections {

    .symptom {
        display: inline-block;
        background: #F3F3F0;
        padding: 5px 43px 5px 14px;
        margin-right: 7px;
        margin-bottom: 7px;
        border-radius: 17px;
        position: relative;
        
        @include media($mobileOnly){
            display: block;
        }
        button {
            width: 26px;
            height: 27px;
            position: absolute;
            top: 3px;
            right: 7px;
            
            i {
                background: #A1519F;
                color: white;
                border-radius: 50%;
                font-size: 16px;
                padding: 5px;
                font-weight: bold;
                transition: background 0.15s ease-in-out;
            }
            
            &:hover{
                i {
                    background: #894687;
                }
            }
        }
    }
    
    .see-all-selections {
        margin-left: 18px;
    }
    
    .see-all-selections,
    .collapse-selections {
        #main-content & {
            @include media($ptabAndLess){
                margin: 0 auto;
                display: block;
            }
        }
    }
}

.prioritize-list {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0;
    
    li {
        margin-bottom: 16px;
        border-radius: 3px;
        background: white;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
        position: relative;
        padding: 30px 25px;
        
        @include media($ptab){
            padding: 50px 290px 50px 70px;
        }
        
        .info {
            position: absolute;
            top: 30px;
            left: 25px;
            
            @include media($ptab){
                top: 49px;
                left: 35px;
            }
            @include media($desk){
                top: 53px;
            }
            i {
                color: $green;
            }
            
            + p {
                @include media($mobileOnly){
                    padding-left: 40px;
                }
            }
        }
        
        .prioritize-button {
            display: block;
            
            @include media($ptab){
                position: absolute;
                top: 34px;
                right: 35px;
                width: 225px;
            }
        }
        
        .issue-description {
            display: none;
        }
    }
}

.support-block {
    
    &.image-text {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 20px 10px;
        position: relative;
        margin-top: 35px;
        
        @include media($ptab){
            padding: 40px 20px;
        }
        
        @include media($tab){
            padding: 60px 30px;
        }
        
        @include media($desk){
            padding: 80px 100px;
        }
        *:not(.button) {
            color: white;
        }
        
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
        }
        
        .wysiwyg {
            position: relative;
            z-index: 1;
            margin: 0;
            
            h1, h2 {
                @include media($mobileOnly){
                    text-transform: uppercase;
                }
            }
            
            > * {
                @include media($mobileOnly){
                    margin-bottom: 0;
                }
            }
        }
    }
    
    &.accordion {
        > .wysiwyg {
            margin: 1em 0 !important;
        }
        
        .expander__title {
            i {
                color: $blue;
                position: relative;
                top: -2px;
            }
        }
        
        .expanders {
            .wysiwyg {
                margin-top: 1em;
            }
        }
    }
    
    &.apps_tools {
        .resource-group__content__wrap {
            padding-left: 0;
            padding-right: 0;
        }
        
        .resource-group__item-info {
            .resource-group__item-content {
                p {
                    font-size: 16px;
                    margin-top: .5em;
                    margin-bottom: .5em;
                }
            }
        }
        
        .wysiwyg {
            margin-top: 1em !important;
        }
    }
}

p + .support-blocks {
    margin-top: 4rem;
}

.support-blocks {
    
    .support-blocks-type {
        > h2 {
            margin-bottom: 25px;
            
            @include media($mobileOnly){
                text-align: center;
            }
        }
        
        &:not(:last-child){
            margin-bottom: 65px;
        }
    }
    
    .expanders {
        padding: 0;
        margin: 0;
        
        .expander__title {
            margin-bottom: 0;
        }
        
        .expanders {
            .expander--grey {
                background: #EBEBE6;
            }
            
            .expander__title {
                font-size: 0.875em;
                margin-bottom: 0;
            }
        }
    }
}

.static-blocks {

    .support-block {
        margin: 0;
        margin-bottom: 26px;
        
        @include media($mobileOnly){
            margin-bottom: 15px;
        }
        &.accordion {
            > .wysiwyg {
                margin-bottom: 1em;
            }
            
            .expanders {
                padding: 0;
                margin: 0;
                
                .expander--grey {
                    background: #EBEBE6;
                }
                
                .expander__title {
                    font-size: 0.875em;
                    margin-bottom: 0;
                }
            }
        }
        
        &.apps_tools, &.accordion {
            background: #F3F3F0;
            padding: 2rem;
        }
        
        .static-title {
            text-transform: uppercase;
            letter-spacing: 1px;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

// --
// pathfinder-step1
// --
.main-content.pathfinder-welcome {
    > section {
        p:not(.small) {
            font-size: 20px;
        }
        &.intro {
            h1 {
                font-family: $font-amatic;
                font-size: 40px;
                
                @include media($desk){
                    font-size: 70px;
                }
            }
        }
        
        &.banner {
            box-shadow: none;
        }
        &.start {
            .column:last-child {
                .column__inner {
                    text-align: center;
                }
                a {
                    @include media($desk){
                        min-width: 320px;
                    }
                }
            }
            .column {
                @include media($mobileOnly){
                    border-bottom: none;
                }
            }
        }
    }
}    
// --
// pathfinder-step1
// --
.main-content.pathfinder-step1 {
    
    .intro {
        @include media($desk){
            margin-top: 120px;
        }
    }
    
    .selection-status {
        text-align: center;
        padding: 20px;
        background-color: #F3F3F0;
        margin: 20px -2em 0;
        
        @include media($tab){
            margin: 0;
            width: 25%;
            float: left;
            background-image: url(../img/pathfinder/bgs/bg-sand5.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 60px 0 50px;
            background-color: transparent;
        }
        
        .pathfinder-clear-selections-num {
            font-size: 16px;
            line-height: 1.1875;
            font-weight: 500;
            display: inline;
            
            @include media($tab){
                display: block;
                font-size: 32px;
            }
            + span {
                font-size: 16px;
                line-height: 1.333;
                display: inline;
                margin-right: 60px;
                @include media($tab){
                    display: block;
                    font-size: 18px;
                    margin-right: 0;
                }
            }
        }
        
        .pathfinder-clear-selections {
            font-size: 13px;
            line-height: 1.2;
            color: $blue;
            text-transform: uppercase;
            
            @include media($tab){
                font-size: 10px;
            }
        }
    }
}

.pathfinder-questionnaire {
    
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    
    .container {
        @include media($mobileOnly){
            padding: 0 10px;
        }
    }
    
    .pfq-slider, .pfq-search {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        max-width: 620px;
        
        @include media($mobileOnly){
            padding: 0 1em;
            overflow: visible;
        }
    }
    
    .pfq-container, .pfq-search-container {
        position: relative;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform,-webkit-transform;
        box-sizing: content-box;
    }
    .pfq-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    // pagination
    .slider__pager {
        width: calc((100% - 445px) / 2);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        text-align: center;
        width: 48px;
        
        @include media($ptab){
            width: 56px;
        }
        
        @include media($desk){
            width: 64px;
        }
        
        i {
            color: $blue;
            font-size: 4rem;
            line-height: 1;
            cursor: pointer;
        }
        
        &.slider__pager--next {
            right: -10px;
        }
        
        &.slider__pager--prev {
            left: -10px;
        }
        
        &.swiper-button-disabled {
            opacity: 0 !important;
            
            i {
                cursor: auto;
            }
        }
    }
    
    .pfq__pagination-wrapper {
        text-align: center;
        margin-top: 40px;
        
        span {
            font-style: italic;
            font-size: 15px;
            color: #7B827F;
            line-height: 1.3;
        }
    }
    .pfq__pagination {
        counter-reset: section;
        display: inline;
        
        .swiper-pagination-bullet {
            display: inline-block;
            width: 0px;
            opacity: 0;
            
            &:before {
                counter-increment: section;
                content: counter(section);
                display: inline;
            }
            
            &.active {
                width: auto;
                opacity: 1;
            }
        }
    }
    .pfq-slider{
        &:not(.show-slider){
            .pfq__pagination-wrapper, .slider__pager {
                opacity: 0;
                pointer-events: none;
            }
        }
        
        &.show-slider{
            .pfq__pagination-wrapper, .slider__pager {
                opacity: 1;
            }
        }
    }
    
    
    .slide-content, .pfq-search-form, .pfq-search-container .no-results {
        max-width: 445px;
        margin: 0 auto;
        
        @include media($mobileOnly){
            padding: 0 10px;
        }
    }
    
    .symptom {
        background-color: #F3F3F0;
        border-radius: 23px;
        min-height: 46px;
        
        &:not(:last-child){
            margin-bottom: 10px;
        }
        
        @include media($ptabAndLess){
            position: relative;
            display: flex;
            align-items: center;
        }
    
        input {
            display: none;
            
            &:checked {
                + label {
                    &:after {
                        @include material-icon('\e5ca');
                        color: white;
                        position: absolute;
                        left: 7px;
                        width: 38px;
                        text-align: center;
                        height: 38px;
                        line-height: 40px;
                        top: calc(50% - 20px);
                        font-size: 25px;
                    }
                    
                    &:before {
                        background-color: $green;
                    }
                }
            }
        }
        
        label {
            font-size: 16px;
            line-height: 1.1875;
            position: relative;
            padding: 13px 15px 13px 55px;
            display: block;
            cursor: pointer;
            
            @include media($ptabAndLess){
                position: static;
                padding: 5px 15px 5px 55px;
            }
            
            &:before {
                content: "";
                width: 38px;
                height: 38px;
                border: 1px solid #D0D0D0;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: calc(50% - 20px);
                left: 5px;
            }
        }
    }
    
    .pfq-search-container{
        display: block;
        margin-bottom: 40px;
        box-sizing: border-box;
            
        @include media($mobileOnly){
            padding: 0 10px;
        }
        
        .no-results {
            padding: 14px 17px;
            box-sizing: border-box;
            display: none;
            margin-top: 15px;
            margin-bottom: -15px;
            border: 1px solid #FFE8B8;
            background-color: #FDF4E1;
            
            @media(max-width: 411px){
                padding: 8px;
            }
                
            &.active {
                display: block;
            }
            
            p {
                margin-bottom: 0;
                color: #685A3E;
                font-size: 15px;
                
                @media(max-width: 411px){
                    font-size: 14px;
                }
            }
        }
    }
    .pfq-search-form {
        border: 1px solid #D6D6D6;
        padding: 5px 50px 5px 37px;
        min-height: 52px;
        position: relative;
        box-sizing: border-box;
        font-size: 15px;
        display: flex;
        align-items: center;
        
        input {
            border: none;
            outline: none;
            height: 40px;
            display: block;
            width: 100%;
            padding: 0;
        }
        
        button{
            &[type="submit"] {
                width: 40px;
                height: 40px;
                border-radius: 3px;
                background-color: $blue;
                color: white;
                font-weight: 500;
                position: absolute;
                top: 5px;
                right: 5px;
                
                &:hover {
                    background-color: $blue-2;
                }
            }
            
            &.clear {
                position: absolute;
                top: 50%;
                left: 10px;
                height: 25px;
                width: 25px;
                transform: translateY(calc(-50% - 1px));
                opacity: 0;
                transition: opacity 0.15s ease-in-out;
                    
                i {
                    color: #A1519F;
                    font-size: 25px;
                    text-align: center;
                    
                }
                
                &:hover {
                    i {
                        color: #81427f;
                    }
                }
                
                &:focus, &:active {
                    outline: none;
                }
                
                &:not(.active) {
                    pointer-events: none;
                }
                
                &.active {
                    opacity: 1;
                }
            }
        }
        
        #pfq-search-form {
            display: none;
        }
    }
}

.symptoms-temp-container {
    display: none;
}

.page-template-pathfinder-step1 .ui-autocomplete {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 5px;
    max-width: 429px;
    z-index: 1;
    border: none !important;
    
    .ui-menu-item {
        margin-left: -38px;
        padding: 0;
        border: 1px solid #d6d6d6;
        background-color: white;
        cursor: pointer;
        width: calc(100% + 89px);
        font-size: 15px;
        color: #747474;
        
        &:hover {
            background-color: #2983fb;
            color: white;
            border: 1px solid #0c47fa;
        }
        + .ui-menu-item {
            border-top: none;
        }
        
        .ui-menu-item-wrapper {
            padding: 3px 0;
            padding-left: 37px;
        }
    }
}

// --
// pathfinder-step2
// --
.main-content.pathfinder-step2 {
    
}

// --
// pathfinder-step3
// --
.main-content.pathfinder-step3 {
    
    .intro {
        .header {
            margin-bottom: 55px;
        }
    }
    
    .selection-summary {
        .header {
            margin-bottom: 0;
            
            a {
                color: #C8A3C7;
                
                &:after {
                    background-color: #C8A3C7;
                }
            }
        }
        h2 {
            font-weight: normal;
            margin-bottom: 0;
            
            @include media($ptabAndLess){
                text-align: center;
                margin-bottom: 20px;
            }
            
            span {
                display: inline-block;
                
                &:first-child {
                    margin-right: 20px;
                    
                    @include media($ptabAndLess){
                        margin-right: 0;
                        display: block;
                        margin-bottom: 15px;
                    }
                }
            }
            .val {
                font-weight: 500;
                border-bottom: 1px solid #3F4643;
                
                @include media($ptabAndLess){
                    display: block;
                    border-bottom: none;
                }
            }
        }
    }
    
    .ramp {
        max-width: 448px;
        height: 198px;
        background-image: url(../img/pathfinder/bgs/bg-ramp.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto;
        background-position: center bottom;
        position: relative;
        
        @include media($mobileOnly){
            width: 272px;
            background-image: url(../img/pathfinder/bgs/bg-ramp-mobile.svg);
            padding-left: 55px;
            background-size: auto;
            background-position: right center;
            position: relative;
        }
        
        div {
            display: inline-block;
            position: absolute;
            
            &:first-child {
                bottom: 25px;
                left: 0;
                
                @include media($mobileOnly){
                    bottom: 30px;
                }
            }
            
            &:nth-child(2) {
                top: calc(50% - 10px);
                left: calc(50% + 20px);
                transform: translateX(-50%);
                
                @include media($mobileOnly){
                    left: auto;
                    right: -40px;
                    top: 80px;
                }
            }
            
            &:last-child {
                top: -10px;
                right: -10px;
            }
            
            input {
                display: none;
                
                &:checked {
                    + label {
                        &:after {
                            display: block;
                        }
                    }
                }
            }
            label {
                position: relative;
                padding-right: 38px;
                cursor: pointer;
                
                &:before, &:after {
                    position: absolute;
                    content: "";
                    border-radius: 50%;
                }
                
                &:before{
                    width: 26px;
                    height: 26px;
                    border: 1px solid #D0D0D0;
                    background-color: white;
                    top: -3px;
                    right: 0;
                }
                
                &:after {
                    width: 16px;
                    height: 16px;
                    background-color: #A1519F;
                    top: 2px;
                    right: 5px;
                    display: none;
                    transition: opacity 0.15s ease-in-out;
                }
                
                /*&:hover {
                    &:after {
                        display: block;
                    }
                }*/
                
                &:active {
                    &:after {
                        border: 1px solid white;
                    }
                }
            }
        }
        
        + .button {
            max-width: 448px;
            display: block;
            width: 100%;
            margin: 35px auto 0;
            
            @include media($mobileOnly){
                width: 272px;
            }
        }
    }
}

// --
// pathfinder-step4
// --
.main-content.pathfinder-step4 {
    .selection-summary {
        .header {
            padding-right: 190px;
            @include media($ptabAndLess){
                padding-right: 0;
            }
            a {
                color: #C8A3C7;
                &:after {
                    background-color: #C8A3C7;
                }
                @include media($ptabAndLess){
                    display: none;
                }
                @include media($tab){
                    position: absolute;
                    top: 6px;
                    right: 2em;
                }
            }
        }
        
        .selections {
            padding-right: 0;
        }
    }
    .intro {
        @include media($desk){
            margin-top: 0 !important; 
        }
            
        .copy {
            @include media($tab){
                padding-left: 62px !important;
            }
            
            @include media($desk){
                padding-left: 74px !important;
            }
            
            > i {
                @include media($tab){
                    top: 50% !important;
                    transform: translateY(-50%) translateX(-50%) !important;
                    left: 22px !important;
                }
                
                @include media($desk){
                    left: 27px !important;
                }
            }
        }
    }
    .symptom-selections {
        .symptom {
            padding: 5px 24px 5px 24px;
        }
    }
    
    .step {
        @include clearfix();
        
        &:not(:last-child){
            margin-bottom: 35px;
        }
        
        .title {
            @include media($mobileOnly){
                text-align: center;
                margin-bottom: 10px;
            }
            @include media($ptab){
                width: 114px;
                float: left;
                padding-top: 2px;
            }
            
            h3 {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.214;
                text-transform: uppercase;
            }
        }
        .values {
            @include media($mobileOnly){
                text-align: center;
            }
            @include media($ptab){
                float: left;
                width: calc(100% - 114px);
            }
            p {
                font-size: 16px;
                line-height: 1.1875;
            }
        }
        
        &.step-1 {
            .title {
                @include media($ptab){
                    width: 114px;
                    float: left;
                    padding-top: 10px;
                }
            }
        }
    }
    
    &#main-content {
        section.intro {
            .copy {
                position: relative;
                width: auto;
                float: none;
                
                @include media($tab){
                    padding-right: 273px;
                }
            }
            
            .button, .pdfprnt-button {
                margin: 0;
                @include media($tab){
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            
            .pdfprnt-button {
                padding: 0;
                
                .button {
                    position: static;
                }
            }
        }
    }
    
}

// --
// pathfinder-general
// --
.main-content.pathfinder-general {

}
