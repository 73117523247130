@import "../neat/neat-helpers";

$max-width: 1200px;

// Define your breakpoints
$mobileOnly: new-breakpoint(max-width 600px);
$ptabAndLess: new-breakpoint(max-width 768px);
$tabAndLess: new-breakpoint(max-width 1024px);
$deskAndLess: new-breakpoint(max-width 1200px);
$ptab: new-breakpoint(601px);
$tab: new-breakpoint(769px);
$desk: new-breakpoint(1025px);
$wideDesk: new-breakpoint(1201px);

$laptopHeightOnly: new-breakpoint(max-height 768px);
$mobileHeightOnly: new-breakpoint(max-height 600px);

.wide-container {
  max-width: rem(1200);
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @include row;
  @include media($desk) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.container {
  @extend .wide-container;

  max-width: rem(1020);
}

.container--wrapper {
  @extend .container;

  padding-left: 0;
  padding-right: 0;
}

.container--clean {
  max-width: rem(1020);
  margin: 0 auto;

  @include media($desk) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.narrow-container {
  @extend .container;

  max-width: rem(800);
}

.narrow-container--clean {
  max-width: rem(800);
  margin: 0 auto;

  @include media($desk) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.tight-container {
  @extend .container;

  max-width: 25rem;
}

.tight-container--clean {
  max-width: 25rem;
  margin: 0 auto;
}

.reset-layouts {
  .wysiwyg {
    padding: 0;
  }
}

.reset-containers {
  .wide-container,
  .container,
  .narrow-container,
  .tight-container {
    max-width: none;
  }
}
