@mixin placeholder($color, $weight: 300, $opacity: 0.6) {
  &::-webkit-input-placeholder {
    color: $color;
    font-weight: $weight;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    color: $color;
    font-weight: $weight;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
    font-weight: $weight;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    color: $color;
    font-weight: $weight;
    opacity: $opacity;
  }
}

@mixin material-icon($content){
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  content: $content;
}

@mixin clearfix(){
	&:before, &:after {
		content: " ";
		display: table;
		clear: both;
	}
}