// HOMEPAGE

// Outer container for either homepage cover image or slideshow
.home-hero {
  position: relative;
}

.home-ctas {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(50%);

  .container {
    @include media($mobileHeightOnly) {
      max-width: 40rem;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    display: inline-flex;
    color: $white;
    background: $teal-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
    width: calc( (100% - 10px * 2 ) / 3);
    padding: 15px;
    line-height: 1.4;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 1px 1px 6px rgba($black, 0.1);
    transition: background 0.3s, box-shadow 0.3s;
    border-radius: 3px;

    &:hover {
      color: $white;
      background: lighten($teal-2, 4%);
      box-shadow: 3px 3px 6px rgba($black, 0.15);
    }

    @include media($ptab) {
      width: calc( (100% - 12px * 2 ) / 3);
      font-size: 16px;
      min-height: 75px;
    }

    @include media($tab) {
      width: calc( (100% - 15px * 2 ) / 3);
      font-size: 20px;
      min-height: 100px;
    }

    @include media($mobileHeightOnly) {
      font-size: 12px;
      min-height: auto;
    }
  }
}

.home-cover {
  color: $white;
  background-color: $blue;
  height: 88vh;
  min-height: 45vw;
  text-align: center;
  box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  // background-attachment: fixed;
  text-shadow: 0 0 10px rgba($black, 0.15);

  &::before {
    content: '';
    @extend .full-size;
    // background: linear-gradient(to top, darken($blue, 5%) 0%, transparent 100%);
    box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.1);
  }

  @include media($mobileOnly) {
    min-height: 450px;
  }

  > * {
    position: relative;
  }

  @include media($tab) {
    // background-size: contain;
  }

  @include media($laptopHeightOnly) {
    // background-size: cover;
    // background-position: 50% -10vh;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__container {
    padding-bottom: 55px;

    @include media($ptab) {
      padding-bottom: 70px;
    }

    @include media($tab) {
      padding-bottom: 85px;
    }

    @include media($mobileHeightOnly) {
      padding-bottom: 55px;
    }
  }

  h1 {
    margin-top: 0;
    font-family: $font-amatic;
    font-size: 40px;
    letter-spacing: 0;
    margin-bottom: 5px !important;
    letter-spacing: -1px;

    @include media($ptab) {
      font-size: 55px;
      letter-spacing: 0;
    }

    @include media($desk) {
      font-size: 70px;
    }

    // @include media($laptopHeightOnly) {
    //   font-size: 50px;
    // }

    @include media($mobileHeightOnly) {
      font-size: 40px;
    }
  }

  p {
    margin: 0;
    font-size: 18px;
    line-height: 1.4;

    @include media($ptab) {
      font-size: 21px;
    }

    @include media($desk) {
      font-size: 24px;
    }

    // @include media($laptopHeightOnly) {
    //   font-size: 22px;
    //}

    @include media($mobileHeightOnly) {
      font-size: 15px;
      br {
	      display: none;
      }
    }
  }
}

// Good grief, if you let this crap in the final build I'll disown you.
.home-slider {
  height: 80vh;
  min-height: rem(450);
  background-color: $blue;
  color: white;
  text-align: center;
  position: relative;

  .home-slider__container,
  .home-slider__slider,
  .home-slider__slides,
  .home-slider__slide {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .home-slider__slides {
    white-space: nowrap;
  }

  .home-slider__slide {
    display: flex;
    white-space: normal;
    pointer-events: none;
    background-size: cover;
    background-position: center top;
    // background-attachment: fixed;
    flex-shrink: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.1);

    .handheld & {
      background-attachment: initial;
    }

    &.slide-active {
      opacity: 1;
      pointer-events: auto;
      z-index: 10;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .home-slider__title-container {
    @extend .narrow-container;

    left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    margin: auto auto 5rem auto;
    z-index: 10;
    text-align: left;

    .ie-fixes & {
      align-self: flex-end;

      // bottom: 0
      // position: absolute

    }
  }

  .home-slider__title {
    display: inline-block;
    font-family: $font-amatic;
    text-align: left;
    line-height: 1.1;
    letter-spacing: 0;
    font-size: rem(48);

    @include media($tab) {
      line-height: 1.15;
      letter-spacing: rem(1);
    }
  }

  .home-slider__pagination {
    position: absolute;
    bottom: 2rem;
    z-index: 10;
    text-align: center;
    width: 100%;
    left: 0;

    .swiper-pagination-bullet {
      border: 1px solid white;
      border-radius: 50%;
      padding: 0;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &::before {
        border-color: white;
        transform: scale(0.5);
        background: white;
        transition: transform 0.3s linear;
      }

      &.active,
      &:hover {
        &::before {
          transform: scale(1.05);
        }
      }
    }
  }

  .home-slider__cta {
    @extend .large-link;

    position: absolute;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

// Homepage conversation UI
.home-convo {
  background-color: $offwhite;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center rem(50);
  background-image: url(../img/convo-bg.svg);

  .no-action {

  }

  &__container {
     @extend .wide-container;
     padding-top: rem(50);
     padding-bottom: rem(50);
  }

  h2, p {
    text-align: center;
  }

  h2 {
    margin-bottom: rem(30);
  }

  p {
    max-width: rem(650);
    margin: 0 auto rem(50);
    font-size: rem(18);
  }

  // Question group
  &__group {
    padding: rem(30) 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.hidden-group {
      padding: 0;

      > .home-convo__q,
      > .home-convo__back {
        display: none;
        visibility: none;
      }
    }
  }

  // Question wrapper
  &__q {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    padding: 0 rem(25) rem(40);
    @include media($ptab) {
      flex-basis: 50%;
      &:nth-of-type(2n+1) {
        .home-convo__q-container {
          border-bottom-left-radius: rem(5);
        }
      }
      &:nth-of-type(2n) {
        .home-convo__q-container {
          border-bottom-right-radius: rem(5);
        }
      }
    }
    @include media($desk) {
      flex-basis: 33.33333%;
      &:nth-of-type(2n+1) {
        .home-convo__q-container {
          border-bottom-left-radius: rem(47);
        }
      }
      &:nth-of-type(2n) {
        .home-convo__q-container {
          border-bottom-right-radius: rem(47);
        }
      }
      &:nth-of-type(3n+1), &:nth-of-type(3n+2) {
        .home-convo__q-container {
          border-bottom-left-radius: rem(5);
        }
      }
      &:nth-of-type(3n) {
        .home-convo__q-container {
          border-bottom-right-radius: rem(5);
        }
      }
    }
  }

  // Question tooltip
  &__q-container {
    background: $green;
    color: white;
    font-weight: 500;
    font-size: rem(19);
    max-width: rem(390);
    width: 100%;
    height: rem(94);
    padding: 0 rem(60);
    border-radius: rem(50);
    line-height: 1.3;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
    user-select: none;
    &:hover {
      background: darken($green, 9%);
      color: white;
    }
    &:active {
      background: darken($green, 15%);
      box-shadow: inset rem(3) rem(3) rem(5) rgba(black, 0.1);
    }

  }

  // Back button
  &__back {
    display: block;
    flex-basis: 100%;
    text-transform: uppercase;
    font-size: rem(19);
    letter-spacing: rem(1);
    font-weight: 700;
    margin-top: rem(20);
    text-align: center;
    @include media($ptab) {
      margin-left: rem(30);
      text-align: left;
    }
  }

  &__back-btn {
    cursor: pointer;
    position: relative;

    > * {
      vertical-align: middle;
    }

    span {
      position: relative;
      line-height: rem(30);
      display: inline-block;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: rem(2);
        width: 100%;
        background: $blue;
        transition: transform 0.3s;
      }
    }
    svg {
      margin-right: rem(15);
      width: rem(18);
      transition: transform 0.3s;
    }
    &:hover {
      span:before {
        transform: scaleX(0.95);
      }
      svg {
        transform: translateX(rem(5));
      }
    }
  }

}

.page-cover {
  position: relative;
  height: 100vh;
  background-color: $blue;
  color: white;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  text-align: center;
  box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.1);

  .handheld & {
    background-attachment: initial;
  }

  .page-cover__title {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 2rem;
    font-size: em(28);

    @include media($desk) {
      font-size: em(58);
    }
  }

  .page-cover__lead {
    @extend .lead;

    margin-bottom: 4rem;
  }

  .page-cover__cta {
    @extend .large-link;
  }

  .page-cover__scroll-prompt {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    cursor: pointer;

    &:hover {
      i {
        transform: translate(-50%, 0.5rem);
      }
    }

    i {
      font-size: 3rem;
      transform: translateX(-50%);
      transition: transform 0.3s ease;
    }
  }
  a:hover {
    color: #fff;
    text-decoration: underline;
  }
}



.location-donate-btn {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  box-shadow: 1px 2px 6px 0 rgba($black, 0.5);
  background: linear-gradient(180deg, #76CEC2 0%, #53B4B6 100%);
  color: $white;

  &:hover {
    color: $white;
    background: linear-gradient(180deg, darken(#76CEC2, 3%) 0%, darken(#53B4B6, 3%) 100%);
  }
}

.home {
  .story-cards {

    &--stories {
      background-color: $offwhite;
      background-image: url(../img/convo-bg.svg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center rem(50);

      h2 {
        color: $blue;
      }
    }
  }


  .location-cards {
    background: $blue;

    h2 {
      margin-top: 0;
    }
  }

  .news-cards {
    background: $blue-2;

    h2 {
      margin-top: 0;
    }
  }
}

// LOCATIONS
.locations-map {
  position: relative;
  overflow: hidden;

  // margin-bottom: 4rem
  .locations-map__container {
    position: relative;
    display: flex;
    height: 100vh;
    max-height: 60rem;
    min-height: 30rem;
    padding: 3rem 0 0 0;

    @include media($desk) {
      padding: 0;
    }


    > div {
      flex: 1;
    }
  }

  .locations-map__tab-triggers {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    overflow: hidden;

    .button {
      width: 50%;
      float: left;
      margin-top: 0;
      border-radius: 0;
    }

    @include media($desk) {
      display: none;
    }


    .map-trigger__centres {
      background: $green;

      .show-centres & {
        background: $green-hover;
      }
    }

    .map-trigger__map {
      background: $green-hover;

      .show-centres & {
        background: $green;
      }
    }
  }

  .locations-map__centres {
    position: absolute;
    right: 0;
    z-index: 10;
    height: calc(100% - 3rem);
    background: white;
    transform: translateX(100%);
    transition: transform 0.25s ease;
    will-change: transform;
    width: 90%;
    overflow-y: auto;
    padding-left: 2rem;
    padding-right: 2rem;

    .show-centres & {
      transform: translateX(0);
    }

    @include media($desk) {
      top: 0;
      margin-top: 0;
      right: auto;
      left: 0;
      height: 100%;
      transform: translateX(0);
      width: 33.33%;
      display: flex;
      flex-direction: column;
      padding: 0;
    }


    &::before,
    &::after {
      @include media($desk) {
        position: absolute;
        content: "";
        width: 100%;
        height: 2rem;
        left: 0;
        z-index: 10;
      }
    }

    &::before {
      top: 0;
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &::after {
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .locations-map__centres-header {
      padding: 2rem 0rem 1rem;
      border-bottom: 1px solid $offwhite;

      // flex-shrink: 0

    }

    .locations-map__centres-title {
      @extend .subtitle;

      margin: 0;
    }

    .locations-map__centres-main {
      flex: 1;
      overflow-y: auto;

      .location {
        margin-bottom: 3rem;
        font-size: 1rem;

        .location__view-centre {
          font-weight: 500;

          i {
            transition: transform 0.3s ease;
          }

          &:hover {
            i {
              transform: translateX(1rem);
            }
          }
        }

        .address {
          p {
            margin-top: 0;
          }
        }
      }
    }

    ul {
      @extend .list-reset;
    }

    .centres-title {
      font-size: 1rem;
      text-transform: capitalize;
      margin-top: 1.5rem;
      margin-bottom: 0;
      a {
        color: $blue;
        &:hover {
          color: $teal;
        }
      }

      + p {
        margin-top: 0;
      }
    }
  }

  #map {
    height: calc(100vh - 3rem);
    padding-bottom: 56.25%;
    position: relative;

    @include media($desk) {
      padding-bottom: 0;
      height: 100%;
      right: 0;
      width: 66.66%;
      position: absolute;
    }
  }
}

// Info cards
.gm-style-iw {
  h1 {
    max-width: 20rem;
    font-size: 1.3rem;
    margin-top: 0;
    a {
      color: $blue;
      &:hover {
        color: $teal;
      }
    }
  }

  p {
    font-weight: 500;
  }
}

// CARDS
.location-cards {
  padding: 4rem 0;
  position: relative;

  .location-cards__container {
    @extend .wide-container;

    // padding: 1rem
    position: relative;
    overflow: hidden;
  }
}

a.location-card {
  width: calc(33.3333% - 28px);
  transition: none;

  .show-slider & {
    transition: all 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);

    // box-shadow: 4px 2px 8px 0 rgba(0,0,0,0.2)
    .location-card__footer {
      transition: color 0.25s ease;
      color: $blue;
    }
  }
}

.location-card {
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  white-space: normal;

  // min-width: calc(33.3% - 30px)
  box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.2);

  .ie-fixes & {
    height: 30rem;

    // display: block

  }

  @include media($tab) {
    min-width: calc(33.3333% - 28px);
  }


  .location-card__header {
    background: $blue-2;
    padding: 1.5rem 0;
    color: white;
    text-align: center;
  }

  .location-card__title {
    @extend .h4;

    text-align: center;
    margin: 0;
    padding-left: 1em;
    padding-right: 1em;
  }

  .location-card__wrapper {
    text-align: center;
    width: 100%;
  }

  .location-card__footer {
    text-transform: uppercase;
    font-weight: 500;
    color: $grey;
    margin-top: auto;
    letter-spacing: 1px;
    padding: 1rem 2rem;
    position: relative;
    background: white;

    .location-card__icon {
      position: absolute;
      padding: 0.9rem;
      background: $green;
      border-radius: 50%;
      right: 1rem;
      top: 0;
      transform: translateY(-50%);
      color: white;
      font-size: 1.6rem;
    }
  }

  .location-hours {
    .location-hours__group {
      margin-bottom: 0;

      span {
        display: inline-block;

        &:first-child {
          &::after {
            content: ": ";
          }
        }
      }
    }
  }

  .location-card__content__operated {
    font-size: 0.8rem;
  }
}

.location-card-temp {
  cursor: pointer;
}

.location-card__content-info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background: transparentize($grey-dark, 0.1);
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 0.9em;
  color: white;
  pointer-events: none;

  > h3:first-child {
    margin-top: 0 !important;
  }

  h3 {
    margin-top: 1rem !important;
    text-transform: none;
  }

  .show-card-info & {
    opacity: 1;
    pointer-events: auto;
  }

  ul {
    padding-left: 2em;
  }
}

.location-card__content__subtitle {
  @extend .subtitle;

  &:first-child {
    margin-top: 0;
  }
}

.location-card__content {
  background-color: $blue-2;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: white;
  flex: 1;

  &::before {
    content: "";
    padding-bottom: 75%;
    width: 100%;
    display: block;
  }

  .coming-soon & {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &::before {
      display: none;
    }
  }
}

.location-card__content_more__link {
  text-decoration: none;
  border: 0;
}

.coming-soon {
  .coming-soon__image {
    background-size: cover;
    background-position: center center;
    width: 50%;
    margin: 0 auto;

    &::before {
      content: "";
      padding-bottom: 100%;
      display: block;
    }
  }

  &:nth-of-type(1n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-1.png");
    }
  }

  &:nth-of-type(2n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-2.png");
    }
  }

  &:nth-of-type(3n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-3.png");
    }
  }

  &:nth-of-type(4n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-4.png");
    }
  }

  &:nth-of-type(5n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-5.png");
    }
  }

  &:nth-of-type(6n) {
    .coming-soon__image {
      background-image: url("../img/cards/foundry-cover-6.png");
    }
  }
}

// LOCATION
.info-tooltip {
  position: fixed;
  z-index: 301;
  bottom: 0.5rem;
  right: 0.5rem;
  padding: 2rem;
  max-width: 30rem;
  text-align: left;
  overflow: hidden;
  transition: box-shadow 0.3s linear;
  pointer-events: none;

  @include media($tab) {
    // position: absolute
  }


  &.expanded {
    pointer-events: auto;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);

    .info-tooltip__content {
      opacity: 1;
      pointer-events: auto;
      transform: none;
    }
  }

  .info-tooltip__title {
    margin-top: 0;
  }

  .info-tooltip__subtitle {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .info-tooltip__content {
    max-height: calc(100vh - 8rem);
    overflow-y: auto;
    padding-bottom: 3rem;
    opacity: 0;
    transform: translateX(-0.5rem);
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    position: relative;
    z-index: 1;
    color: white;
  }

  span {
    display: block;
  }
}

.location-hours {
  h3 {
    @extend .subtitle;

    margin-bottom: 0;

    &:nth-of-type(1) {
      margin-top: 0;
    }
  }

  .location-hours__group {
    margin-bottom: 0.5rem;

    span {
      display: block;
    }
  }
}

.info-tooltip__trigger {
  background: $green;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 700;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  pointer-events: auto;
  transition: all 0.3s ease;
  color: white;

  &:hover {
    background: $green-hover;
  }

  .open {
    display: block;

    .expanded & {
      display: none;
    }
  }

  .close {
    display: none;

    .expanded & {
      display: block;
    }
  }
}

.info-tooltip__ripple {
  position: absolute;
  z-index: 0;
  bottom: 1rem;
  right: 1rem;
  background: transparentize($grey-dark, 0.1);
  border-radius: 50%;
  transform: scale(0.005);
  width: 200vw;
  height: 200vh;
  margin-bottom: -100vh;
  margin-right: -100vw;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.15s ease;

  .expanded & {
    opacity: 1;
    transform: scale(1);
    border-radius: 0%;
  }
}

.info-tooltip__social {
  text-align: right;
}

.social-link {
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: inline-block;

  svg {
    border-radius: 50%;
    background: $blue;
    color: white;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
    padding: 0.5em;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateX(-0.2rem);
    }
  }
}

.info-tooltip__prompt {
  background: $blue-hover;
  padding: 0.5rem 1rem;
  position: absolute;
  display: block;
  z-index: 500;
  max-width: 50vw;
  font-size: 0.7em;
  line-height: 1.3;
  opacity: 0;
  right: 4rem;
  bottom: 1.5rem;
  animation-delay: 2s;
  animation-duration: 4s;
  animation-direction: both;
  animation-timing-function: ease;
  animation-name: fadeInOut;

  &::before {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: $blue-hover;
    border-width: 5px;
  }
}

.info-tooltip__prompt--right {
  @extend .info-tooltip__prompt;

  bottom: -1rem;

  &::before {
    left: auto;
    right: 1.5rem;
  }

  left: auto;
  right: 0.5rem;
  animation-delay: 3s;
}

.single-location-map {
  #map {
    min-height: 30rem;
  }
}

// SINGLE LOCATION
.location-contact-info {
  padding: 4rem 0;
  background: $offwhite;

  .location-contact-info__title {
    margin-top: 0;
  }

  .location-contact-info__block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  .location-contact-info__directions {
    font-weight: 500;
  }

  .column {
    flex: 1;
  }

  .location-hours {
    h3 {
      @extend .subtitle;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.location-contact__social {
  a {
    @extend .icon-circle;

    display: inline-block;
    line-height: 1;
    padding: 0.5rem;
    color: $blue;

    &:hover {
      background: $blue;
      color: white;
    }
  }

  svg {
    width: 1.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

// VIDEO MODAL
.video-modal {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    pointer-events: auto;
    z-index: 1000;

    .video-modal__content {
      transform: none;
    }
  }

  .video-modal__bg {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: black;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.6s ease;

    &:hover {
      opacity: 0.6;
    }
  }

  .video-modal__container {
    @extend .narrow-container;

    z-index: 10;
    position: relative;
    width: 100%;
  }

  .video-modal__content {
    transition: transform 0.3s ease;
    transform: translateY(1rem);
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video-modal__close {
    position: absolute;
    right: 1rem;
    top: -3rem;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    @include media($tab) {
      right: -1rem;
      top: -1rem;
    }


    &:hover {
      transform: scale(1.05);
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// MEDIA ARCHIVE
.story-slider {
  @extend .slider-outer;

  margin-bottom: rem(80);

  .slider__pagination {
    position: relative;
    bottom: 0;
  }

  .story-slider__container {
    overflow: hidden;
  }
}

.story-slide {
  display: flex;
  justify-content: space-between;
  white-space: normal;
  width: 100%;
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: $offwhite;

  @include media($mobileOnly) {
    display: block;
  }


  &.slide-active {
    opacity: 1;
  }

  > div {
    flex-shrink: 0;
    width: calc(50% - 2rem);

    @include media($mobileOnly) {
      width: auto;
    }
  }

  .story-slide__image {
    flex: 1;
    position: relative;

    // height: 100%
    overflow: hidden;
    line-height: 0;

    @include media($mobileOnly) {
      position: relative;
      height: auto;
    }


    img {
      width: 100%;
      min-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;

      @include media($mobileOnly) {
        min-height: 0;
        position: relative;

        // max-height: 10rem
      }
    }
  }

  .story-slide__content {
    padding: 2rem 3rem;
    flex-shrink: 0;

    @include media($mobileOnly) {
      padding: 2rem;
    }
  }

  .story-slide__title {
    margin-top: 0;
    font-size: 1.5em;
  }

  .story-slide__read-more {
    margin-top: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $blue;
    display: flex;
    align-items: center;

    i {
      margin-left: 1rem;
    }
  }
}

.news-articles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-article {
  width: calc(50% - 1rem);
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  .ie-fixes & {
    display: block;
  }

  .news-article__image {
    // flex: 1
    overflow: hidden;
    position: relative;
    line-height: 1;
    max-height: 15rem;
    display: block;

    &.no-featured {
      background: $blue-2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      transition: transform 3s linear;
      width: 100%;
      flex: 1;
      display: block;
    }
  }

  .news-articles__title {
    @extend .subtitle;
  }

  .news-article__info {}

  .news-article__excerpt {
    @include media($mobileOnly) {
      display: none;
    }
  }

  .news-article__date {
    @extend .date;

    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background: $blue;
    font-size: 0.8rem;
    color: white;
  }

  .news-article__title {
    @extend .h3;

    margin-top: 1rem;
    margin-bottom: 0;
  }

  .news-article__excerpt {
    p {
      margin: 0;
    }
  }

  .read-more {}

  .news-article__more {
    @extend .subtitle;

    display: flex;
    align-items: center;
    font-weight: bold;
    letter-spacing: 1px;

    i {
      margin-left: 1rem;
      transition: transform 0.3s ease;
    }

    &:hover {
      i {
        transform: translateX(0.5rem);
      }
    }
  }
}

// PAGINATION
.pagination {
  margin-top: 2rem;
  margin-bottom: 2rem;

  .pagination__nav {
    .page-numbers {
      font-size: em(24);
      width: 1.5em;
      line-height: 1.5em;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
    }

    .current {
      cursor: not-allowed;
    }

    a {
      color: $blue;

      &:hover {
        color: white;
        background: $blue;
      }
    }
  }
}

// SINGLE STORY
.single-story_type {
  .page-header {
    min-height: rem(400);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;

    &::before {
      content: '';
      @extend .full-size;
      background: rgba($grey-dark, 0.35);
    }
  }
}

.story-lead {
  color: $body-color;
  padding: 3rem 0 2rem;
  text-align: center;
  // background: rgba(255, 255, 255, 0.8);
  margin-top: auto;
  // background-size: cover;
  // background-attachment: fixed;
  position: relative;
  z-index: 1;

  .handheld & {
    background-attachment: initial;
  }

  .story-lead__intro {
    @extend .lead;
    color: $white;
  }
}

.story-title {
  @extend .h1;
  color: $white;

  margin-top: 0;
}

.story-scroll {
  @extend .large-link-base;

  color: $white;
  margin-top: 2rem;

  &:hover {
    i {
      transform: translateY(0.5rem);
    }
  }

  span {
    display: block;
  }

  i {
    transition: transform 0.3s ease;
  }
}

.share-story {
  text-align: center;

  .share-story__link {
    @extend .large-link;

    color: $grey;

    &::after {
      background: $grey;
    }

    &:hover {
      color: $grey;
    }
  }
}

.more-stories {
  text-align: center;
  margin: 6rem 0rem 2rem;

  .more-stories__trigger {
    @extend .large-link-icon;

    color: $blue;
  }
}

.search-page-form {
  margin-top: 40px;

  .search-form {
    input,
    button {
      background: white;
      color: $grey-dark;
    }
  }
}

.search-post {
  position: relative;
  padding: 30px 0;

  &__thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      @extend .full-size;
      background: rgba(white, 0.2);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__title {
    margin: 0;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    border: 1px solid rgba($blue, 0.15);
  }

  .read-more {
    text-transform: capitalize;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  @include media($ptabAndLess) {
    &__thumbnail {
      width: 100%;
      padding-bottom: 60%;
      display: block;
      margin-top: 25px;
    }
  }

  @include media($tab) {
    display: flex;
    // flex-direction: row-reverse;

    &__thumbnail {
      margin-left: 25px;
      height: 180px;
      flex-basis: 35%;
    }

    &__copy {
      flex-basis: 100%;
    }

    &--has-thumbnail {
      .search-post__copy {
        flex-basis: 65%;
      }
    }
  }
}

.search-pagination {
  margin-top: 30px;
  text-align: center;

  h2 {
    display: none;
  }

  .page-numbers {
    padding: 0 1px;
    &.current {
      opacity: 0.4;
    }
  }
}

.stories-articles {
  margin-bottom: 30px;
  position: relative;

  &::before {
    content: '';
    @extend .full-size;
    background: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 1;
  }

  &.disabled {
    &::before {
      opacity: 1;
      pointer-events: auto;
    }
  }
}


.filters {
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;

  @include media($ptabAndLess) {
    justify-content: space-between;
  }

  &__menu {
    margin-left: 10px;
    width: 250px;
    position: relative;

    @include media($ptabAndLess) {
      margin-left: 0;
      width: calc((100% - 10px) / 2);
    }

    &__select {
      font-size: 1.25em;
      font-weight: 500;
      line-height: 1.3;
      background: $offwhite;
      padding: 8px 10px;
      width: 100%;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include media($ptabAndLess) {
        font-size: 1em;
      }
    }

    &__dropdown {
      border: 1px solid $offwhite-dark;
      display: none;
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 2;
      background: $white;

      &.active {
        display: block;
      }

      ul {
        @extend .list-reset;
        max-height: 300px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar-track {
          background-color: $offwhite;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: $offwhite;
        }

        &::-webkit-scrollbar-thumb {
          background-color: darken($offwhite-dark, 8%);
        }

        li {

          a {
            display: block;
            padding: 5px 10px;
            line-height: 1.2;
            font-size: 1em;
            transition-duration: 0s;

            &:hover {
              background: $offwhite;
              color: $body-color;
            }
          }

          &.selected {
            font-weight: 500;
            color: $blue;
            // pointer-events: none;

            a {
              &:hover {
                color: $blue;
              }
            }
          }
        }
      }

    }
  }
}
