// SIMPLE COLUMNS
.columns {
  display: block;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 0;

  @include media($mobileOnly) {
    margin-top: 2rem;
  }


  &__title {
    @extend .subtitle;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media($mobileOnly) {
      display: block;
    }
  }

  .column {
    padding: 1rem 0 0 0;

    @include media($desk) {
      // flex: 1
    }


    @extend .header-reset;

    @include media($mobileOnly) {
      width: 100%;
      padding-bottom: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid $offwhite;
      }
    }
  }
}

.column {

  @include media($ptab) {
    .col-count-2 &,
    .col-count-3 &,
    .col-count-4 & {
      width: calc((100% - 30px * 1) / 2);
    }
  }

  @include media($desk) {

    .col-count-3 & {
      width: calc((100% - 30px * 2) / 3);
    }

    .col-count-4 & {
      width: calc((100% - 30px * 3) / 4);
    }
  }


}

.download-area {
  @extend .columns;

  .column {
    flex: 1;
  }

  .download__title {
    @extend .h4;
  }
}

// WYSIWYG
.wysiwyg {
  margin: 4rem 0;

  a {
    color: $blue;
    font-weight: 400;
    @extend .link-underline;
    border-bottom-color: rgba($white, 0);

    b, strong {
      font-weight: 400 !important;
    }

    &:hover {
      color: $blue-hover;
      border-bottom-color: $blue-hover;
    }

    &.large-link {
      font-weight: 700;
    }
  }

  ul {
    list-style: none;
    padding-left: 1em;

    @include media($mobileOnly) {
      padding: 0;
    }

    li {
      position: relative;
      padding-left: 1em;
      margin-bottom: 0.4em;

      &::before {
        position: absolute;
        left: 0;
        content: "•";
        color: $blue;
      }
    }
  }

  ol {
    list-style: none;
    padding-left: 1em;
    counter-reset: ol_li;

    @include media($mobileOnly) {
      padding: 0;
    }

    li {
      position: relative;
      padding-left: 1em;
      margin-bottom: 0.4em;

      &::before {
        position: absolute;
        counter-increment: ol_li;
        content: counter(ol_li) '.';
        left: -.25em;//0;
        //top: 0.15em;
        //line-height: 1;
        //font-size: em(12);
        color: $blue;
      }
    }
  }

  h1, h2, h3, h4 {
    // @extend .subtitle;

    letter-spacing: 0.5px;
    text-transform: none;
  }
}

// SLIDER
.slider {
  .slider__container {
    overflow: hidden;
    position: relative;
  }

  .slider__slide {
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;

    // pointer-events: none
    &.slide-active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .slider__pager {
    display: none;
    transition: all 0.3s ease;
  }
}

.slider__pagination {
  margin-top: 1rem;
  text-align: center;

  .swiper-pagination-bullet {
    display: inline-block;
    cursor: pointer;
    padding: 0.5rem;

    &:not(:last-child) {
      // margin-right: 1rem

    }

    &::before {
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: transparent;
      border: 1px solid $blue;
      display: block;
      transition: all 0.25s ease;
    }

    &.active {
      &::before {
        background: $blue;
        border-color: $blue;
      }
    }

    &:hover {
      &::before {
        border-color: $blue;
        background: transparentize($blue, 0.6);
      }
    }
  }
}

// QUOTE SLIDER
.quotes {
  background-color: $grey-dark;
  background-image: url(../img/backgrounds/testimonial-illustration.svg);
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  color: white;
  padding: rem(80) 0;

  @extend .testimonial;

  blockquote {
    margin: 0;

    p:first-child {
      margin-top: 0;
    }
  }

  .slider__pager {
    display: none;
  }
}

// INFO SLIDER
.info-slider {
  margin: 4rem 0;

  .info-slider__container {
    overflow: hidden;
  }

  .info-slider__info {
    @include media($ptab) {
      float: left;
      width: 40%;
    }


    p {
      @extend .lead;
    }
  }

  .info-slider__title {
    @extend .subtitle;

    margin-top: 0;
  }

  .info-slider__learn-more {
    @extend .large-link--blue-all;

    margin-bottom: 2rem;
  }

  .info-slider__slider {
    @include media($ptab) {
      float: right;
      width: 60%;
      padding-left: 4rem;
    }
  }

  .slider__slide {
    width: 100%;
  }

  .info-slider__slide-image {
    max-width: 100%;
    height: auto;
  }

  .info-slider__slide-caption {
    font-weight: 300;
    font-size: 0.8em;
    color: $grey;
  }
}

.info-slider--non-active {
  @extend .info-slider;

  .slider__slide {
    opacity: 1;
    position: relative;
  }
}

// NEWS
.news {
  margin: 4rem 0;

  .news__title {
    margin-bottom: 3rem;
  }

  .slider {
    overflow: hidden;
    position: relative;
  }

  .slider__container {
    overflow: visible;
    display: flex;
    white-space: nowrap;
    width: 100%;
  }

  .slider__slide {
    white-space: normal;
    display: inline-block;
    padding-right: 2rem;
    min-width: 34%;

    &:not(:first-child) {
      padding-left: 2rem;
      border-left: 1px solid $offwhite;
    }
  }

  .slider__pagers {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    pointer-events: none;
  }

  .slider__pager {
    pointer-events: auto;
    display: block;
    cursor: pointer;
    transition: all 0.3s ease;
    line-height: 1;

    i {
      font-size: 4rem;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .slider__pager--prev {
    float: left;

    &:hover {
      transform: translateX(-0.5rem);
    }

    img {
      transform: rotate(-180deg);
    }
  }

  .slider__pager--next {
    float: right;

    &:hover {
      transform: translateX(0.5rem);
    }
  }
}

.news-item {
  pointer-events: auto;

  .news-item__date {
    @extend .date;
  }

  .news-item__title {}
}

// STORY CARDS
.story-cards {
  padding: 4rem 0;
  & + .story-cards {
	  padding-top: 0;
  }
  position: relative;

  .story-cards__container {
    @extend .wide-container;

    // padding: 1rem
    position: relative;
    overflow: hidden;
  }
}

.story-card {
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  white-space: normal;
  box-shadow: 2px 1px 6px 1px rgba(0, 0, 0, 0.2);
  background: white;
  position: relative;
  min-width: calc(33.3333% - 28px);
  width: calc(33.3333% - 28px);
  transition: none;
  will-change: transform;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;

  &--story {
    color: $white;
    text-shadow: 0 0 10px rgba($black, 0.1);

    &:hover {
      color: $white;
    }

    &::before {
      content: '';
      @extend .full-size;
      background: linear-gradient(0deg, rgba($black, 0.35), rgba($grey-dark, 0.2));
    }
  }

  &--news {
    color: $body-color;
  }

  .show-slider & {
    transition: all 0.3s ease;
  }

  @include media($tab) {
    &:hover {
      transform: translateY(-5px);

      // box-shadow: 4px 2px 8px 0 rgba(155,155,155,0.3)
      .location-card__footer {
        transition: color 0.25s ease;
        color: $blue;
      }
    }
  }

  &__content {
    position: relative;
    .news-cards & {
	    background-color: $blue;
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &::before {
      content: "";
      padding-bottom: 90%;
      width: 100%;
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__category {
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 14px;
    top: 20px;
    left: 30px;
    text-shadow: 0 0 12px rgba($black, 0.55);
  }

  &__footer {
    padding: 1rem 4rem 1rem 2rem;
    position: relative;
  }

  &__icon {
    position: absolute;
    padding: 0.9rem;
    background: $green;
    border-radius: 50%;
    right: 1rem;
    top: 0;
    transform: translateY(-50%);
    color: white;
    font-size: 1.6rem;

    img {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
    }
  }

  &__title {
    margin-top: 10px;
    font-size: 18px;
    letter-spacing: 0.5px;

    @include media($mobileOnly) {
      font-size: 16px;
    }
  }

  &__description {
    line-height: 1.4;
  }

  .material-icons {
    font-size: 1em;
  }
}

// ROW CTA
.row-cta {
  background-color: $teal;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(../img/cta-row-bg.svg);
  padding: 6rem 0;
  color: white;

  .row-cta__text {
    @extend .lead;
    p {
      margin-top: 0;
      line-height: 1.3;
    }
  }

  .row-cta__link {
    @extend .large-link;
  }
}

// PARTNERS
.partners {
  margin: 4rem 0;

  .partners__header {
    margin-bottom: 2rem;
  }

  .partners__title {}

  .partners__content {
    display: flex;
    flex-wrap: wrap;
  }

  .partners__partner {
    display: inline-block;
    overflow: hidden;
    line-height: 1;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    flex-basis: 25%;
    max-width: 25%;

    @include media($mobileOnly) {
      flex-basis: 50%;
      max-width: 50%;
    }


    &:not(last-child) .partners__partner-content {
      margin-right: 5px;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      transition: transform 3s linear;
      width: auto;
      max-height: 3rem;
    }
  }

  .partners__partner-content {
    border: 1px solid $offwhite;
    padding: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partners__partner-title {
    font-weight: 500;
    line-height: 1.5;
  }
}

// SECTION EXPANDERS
.section-expanders {
  &__container {
    @include media($tab) {
      padding: 0 1rem;
      margin: 0 auto;
      max-width: 1024px;
    }
  }
}

.section-expander {
  margin-bottom: 5px;
  position: relative;

  @include media($tab) {
    padding-bottom: 3rem;
  }


  .section-expander__header__title {
    font-size: em(24);
  }

  &:nth-child(2n) {
    background: $beige-light;

    .section-expander__header__content {
      margin-left: auto;
      // text-align: right;
    }

    .section-expander__header-bg {
      right: auto;
      left: 0;
    }
  }

  &:nth-child(4n+1) {
    .section-expander__header-bg {
      background-position: left top;

      &::after {
        background-image: url(../img/masks/mask-right.svg);
      }
    }
  }

  &:nth-child(4n+2) {
    .section-expander__header-bg {
      background-position: right top;

      &::after {
        background-image: url(../img/masks/mask-left.svg);
      }
    }
  }

  &:nth-child(4n+3) {
    .section-expander__header-bg {
      background-position: left top;

      &::after {
        background-image: url(../img/masks/mask-right-2.svg);
      }
    }
  }

  &:nth-child(4n) {
    .section-expander__header-bg {
      background-position: right top;

      &::after {
        background-image: url(../img/masks/mask-left-2.svg);
      }
    }
  }

  .section-expander__header {
    position: relative;

    @include media($tab) {
      padding: 6rem 0 2rem;
    }


    .section-expander__header__content {
      padding-bottom: 2rem;
      display: none;
      position: relative;
      z-index: 10;

      .wysiwyg-content {
        @extend .wysiwyg;
        margin: 20px 0;

        ul {
          padding-left: 5px;
        }
      }

      @include media($tab) {
        display: block;
        width: 40%;
      }
    }

    &:hover {
      .section-expander__header-bg {
        opacity: 1;
      }
    }
  }

  .section-expander__header-bg {
    position: absolute;
    height: 95%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50%;
    max-width: 40rem;
    max-height: 400px;
    opacity: 0.6;
    transition: opacity 1s ease;
    will-change: opacity;
    display: none;
    background-size: 100% auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    @include media($tab) {
      display: block;
    }


    &::after {
      content: "";
      background-image: url(../img/masks/mask-right.svg);
      position: absolute;
      z-index: 10;
      height: 100%;
      width: 100%;
      left: 0;
      background-size: 100% 100%;
      background-position: center center;

      // content: url(../img/masks/mask-right.svg)

    }

    img {
      width: 100%;
    }
  }

  .section-expander__mobile-intro {
    @include media($tab) {
      display: none;
    }
  }
}

.section-expander__mobile {
  display: block;
  background-color: $offwhite;
  text-align: center;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(62, 70, 67, 0.7);
  }

  @include media($tab) {
    display: none;
  }


  .section-expander__trigger {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 2rem 1rem;
    color: white;
    position: relative;
    z-index: 10;

    span {
      display: block;
      vertical-align: middle;
    }

    i {
      color: $green;
      display: block;
      transition: all 0.25s ease;
      vertical-align: middle;

      .expanded & {
        transform: rotate(135deg);
      }
    }
  }
}

.section-expander__learn-more {
  background: $green;
  padding: 0.7rem;
  line-height: 0;
  border-radius: 50%;
  color: white;
  position: absolute;
  bottom: 1rem;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  outline: none;
  opacity: 0;
  z-index: 10;
  right: 1rem;
  visibility: hidden;

  @include media($tab) {
    visibility: visible;
    left: 50%;
    right: auto;
    opacity: 1;
  }


  span {
    @extend .screen-reader-only;
  }

  i {
    transition: transform 0.25s ease;
    will-change: transform;
  }

  .expanded &,
  &.expanded {
    bottom: -2rem;
    transform: translate(-50%, 2rem);
    background: $offwhite-dark;
    opacity: 1;

    i {
      transform: rotate(135deg);
    }
  }

  .closing & {
    // transform: translate(-50%, 2rem)
    i {
      transform: none;
    }
  }

  &.top {
    position: fixed;
    top: 15px;
    bottom: auto;
  }

  &.bottom {
    position: relative;
    bottom: 0;
  }
}

.section-expander__content {
  background: white;

  .js-enabled & {
    display: none;
  }

  .expanding &,
  .expanded & {
    display: block;
  }

  .wysiwyg-content {
    @extend .wysiwyg;

    outline: none;
    padding: 3rem 0 3rem;

    @include media($tab) {
      padding: 7rem 0 5rem;
    }
  }

  .remove-on-desktop & {
    @include media($tab) {
      display: none !important;
    }
  }
}

.section-expander__content-info {
  transition: all 0.3s ease;

  .js-enabled & {
    opacity: 0;
    transform: translateY(-1rem);
  }

  .expanded & {
    opacity: 1;
    transform: none;
  }
}

// EXPANDER
.expanders {
  margin-top: 2rem;
  margin-bottom: 2rem;
  @extend .container;
}

.expander {
  background: white;
  color: $body-color;
  padding: 2rem;
  margin-bottom: 0.5rem;
  border-top: 1px solid $offwhite;

  &:last-child {
    border-bottom: 1px solid $offwhite;
    margin-bottom: 0;
  }

  .wysiwyg {
    margin: 0;
  }

  .expander__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .expander__title {
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  .expander__content {
    // display: none;
    overflow: hidden;
    height: 0;
  }

  .expander__inner {
    overflow: hidden;
  }

  .expander__state {
    transition: transform 0.5s ease;
    display: inline-block;
    font-size: 2em;
    @extend .no-select;
  }

  &.active {
    // .expander__content {
    //   display: block;
    // }

    > .expander__trigger .expander__state {
      transform: rotate(135deg);
    }
  }

  // expanders with icons in the title
  &.contains-icons {
    .expander__title {
      // padding-left: 100px;
      position: relative;
    }

    .icons-container {
      display: inline;
      margin-left: 10px;
      // position: absolute;
      // left: 0;
      // top: 50%;
      // transform: translateY(-50%);

      .material-icons {
        display: inline;
        color: $blue;
        margin-right: 8px;
        font-size: 24px;
      }
    }

    // phones and online chat page
    /*
    .page-id-1684 & {
      &:nth-of-type(1) {
        .material-icons-chat_bubble,
        .material-icons-call {
          display: inline;
        }
      }
      &:nth-of-type(2) {
        .material-icons-chat_bubble,
        .material-icons-email {
          display: inline;
        }
      }
      &:nth-of-type(3) {
        .material-icons-chat_bubble,
        .material-icons-textsms {
          display: inline;
        }
      }
      &:nth-of-type(4) {
        .material-icons-call {
          display: inline;
        }
      }
    }
    */
  }
}

.expander--grey {
  @extend .expander;

  background: $offwhite;
  border-top: 0;

  .expander__state {
    color: $green;
  }
}

// NEWS
.news {
  @include row;

  .slider__slide {
    position: relative;
    opacity: 1;
  }
}

// TABS
.tabs {
  margin: 4rem 0;

  > .container {
    padding: 0;

    @include media($tab);
  }

  .tabs__nav {
    display: flex;
    position: relative;
    z-index: 10;
    overflow-y: auto;
    background: white;

    // width: 100% !important
    width: 100%;

    a {
      @extend .subtitle;

      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 1rem;
      flex: 1;
      text-align: center;
      margin-right: 1px;
      white-space: nowrap;
      background: $beige-light;
      color: $grey;

      @include media($tab) {
        color: white;
        background: $beige;
      }


      &::before {
        display: inline-block;
        content: "";
        border-radius: 50%;
        vertical-align: middle;
        height: 0.5em;
        width: 0.5em;
        margin-right: 0.5em;
        background: $teal;
        opacity: 0;
        transition: opacity 0.3s ease;

        @include media($tab) {
          display: none;
        }
      }

      &:hover,
      &.active {
        color: $teal;

        @include media($tab) {
          color: white;
          background: $beige-dark;
        }


        &::before {
          opacity: 1;
        }
      }
    }
  }

  .tabs__content {
    position: relative;

    @include media($tab) {
      background: $beige-light;
    }
  }
}

.tab {
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  display: none;
  position: absolute;

  @extend .reset-layouts;

  @extend .reset-containers;

  &.tab-active {
    display: block;
    position: relative;
    opacity: 1;
    z-index: 1;
  }

  .tab__content {
    .tab__content-inner {
      @include media($tab) {
        padding: 4rem;
      }
    }
  }
}

// CTA Grid
.cta-grid {
  margin: 4rem 0 4rem;

  &__intro {
    margin-bottom: 2rem;
  }
}

.cta-grid__title {
  margin-top: 0;
}

.cta-grid__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.cta-grid__item {
  flex: 1;
  padding: 0.5rem;
  display: block;
  transition: none;

  &.nobg:hover {
    .cta-grid__item-content {
      background: $blue !important;
      color: white !important;
    }

    .cta-grid__item-title::after {
      background: white !important;
    }
  }
  &.bg {
	opacity: .8;
	transition: opacity .25s;

    .cta-grid__item-content {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
	}
	&:hover {
      opacity: 1;
	}
  }

  &.social-item {
    position: relative;
  }

  .cols-4 & {
    max-width: 25%;
    flex-basis: 25%;
  }

  .cols-3 & {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  .cols-2 & {
    max-width: 50%;
    flex-basis: 50%;
  }

  .cols-1 & {
    max-width: 100%;
    flex-basis: 100%;
  }

  @include media($ptabAndLess) {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }


  @include media($mobileOnly) {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.cta-grid__item-content {
  width: 100%;
  text-align: center;
  padding: 3rem;
  background-color: $offwhite;
  height: 100%;
  text-align: left;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  transition: all 0.3s ease;
  min-height: 16em;

  @include media($ptabAndLess) {
    min-height: 0;
  }


  p {
    margin-top: 0;
    flex: 1;
  }
}

.cta-grid__social {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  width: auto !important;
}

.cta-grid__item-link {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
  margin-top: auto;
  text-align: left;
  position: relative;
  display: inline-block;
  padding-bottom: 0.2rem;
  align-self: flex-start;
  letter-spacing: 1px;

  &::after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: $blue;
    transition: background 0.3s ease;
  }
}

.cta-grid__item-title {
  margin: 0 0 0.5rem 0;

  // flex: 1
  letter-spacing: 0.5px;
}

// Row CTAS
.row-ctas {
  padding: 2rem 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(../img/cta-row-bg.svg);
  color: white;
}

.row-ctas__container {
  @extend .container;

  margin: 0 auto;
}

.row-ctas__inner {
  display: flex;
  flex-wrap: wrap;

  @include media($mobileOnly) {
    display: block;
  }
}

.row-ctas__item {
  flex: 1;
  padding: 2rem 0;
  position: relative;
  flex-shrink: 0;

  @include media($mobileOnly) {
    min-width: 100%;
    padding: 1rem 0;
    font-size: 1rem;
  }
}

.row-ctas__item-title {
  margin-top: 0;
}

.row-ctas__item-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  &:not(last-child) {
    margin-right: 2rem;
  }
}

.row-ctas__item-description {
  @extend .lead;

  flex: 1;
}

.row-ctas__item-link {
  @extend .large-link;

  font-weight: 500;
  margin: 0;
  text-align: left;
  align-self: flex-start;
}

.row-ctas__main-desc {
  @extend .lead;
}

.row-ctas__social,
.cta-grid__social {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;

  a {
    border: 1px solid white;
    text-align: center;
    margin-right: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 50%;
    position: relative;

    img {
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

// TEAM MEMBERS
.team {
  &__header {
    margin-bottom: 2rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.team-member {

  width: calc((100% - 1.5rem) / 2);
  margin-bottom: 2rem;

  @include media($ptab) {
    width: calc((100% - 1.5rem * 2) / 3);
  }

  &--placeholder {
    pointer-events: none;

    @include media($mobileOnly) {
      display: none;
    }
  }

  &__tile {
    position: relative;
    cursor: pointer;

    &__img {
      width: 100%;
      height: auto;
      display: block;
    }

    &__info {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background: rgba($blue, 0.9);
      color: $white;
      padding: 1rem;
      display: flex;
      height: 100px;
      transition: background 0.3s;

      @include media($mobileOnly) {
        height: 90px;
        font-size: 13px;
      }
    }

    &__info-container {

    }

    &__title {
      margin: 0 0 4px;
    }

    &__role {
      display: block;
      line-height: 1.3;

      @include media($ptab) {
        font-size: 14px;
      }
    }

    &:hover {
      .team-member__tile__info {
        background: $blue;
      }
    }
  }

  &__popup {
    background: rgba($black, 0.4);
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    overflow: scroll;

    &.open {
      pointer-events: auto;
      opacity: 1;

      .team-member__popup__container {
        transform: translateX(-50%);
      }
    }

    &__container {
      position: absolute;
      left: 50%;
      width: 90%;
      max-width: 800px;
      min-height: 300px;
      z-index: 3;
      margin: 20px 0;
      border-radius: 5px;
      box-shadow: 2px 2px 10px rgba($black, 0.6);
      transition: transform 0.3s;
      transform: translate(-50%, 30px);
      overflow: hidden;

      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 20px;
      }

      @include media($tab) {
        margin: 80px 0;

        &::after {
          height: 80px;
        }
      }
    }

    &__header {
      height: 3.5rem;
      background: $blue;
      position: relative;
    }

    &__close {
      color: $white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      display: inline-flex;
      cursor: pointer;

      span {
        font-size: 1.75rem;
        line-height: 1;
      }
    }

    &__body {
      background: $white;
      padding: 2rem;
      overflow: hidden;

      // @include media($ptab) {
      //   display: flex;
      // }
    }

    &__img-container {

      @include media($ptabAndLess) {
        margin: 1rem 0 3rem;
      }

      @include media($tab) {
        width: 240px;
        float: left;
      }
    }

    &__img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }

    &__info {
      @include media($tab) {
        float: left;
        width: calc(100% - 240px);
        padding-left: 2.5rem;
      }
    }

    &__title {
      margin: 0 0 0.2rem;
      font-size: 1.5rem;
    }

    &__role {
      display: block;
    }

    &__content {

    }
  }
}


// PAGE PAGINATION
.page-pagination {
  margin: 4rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    margin-bottom: 1rem;

    span {
      display: inline-block;
      vertical-align: baseline;
    }

    .title {
      @extend .large-link;

      &:hover {
        color: $blue;
      }

      &::after {
        background: $blue;
      }
    }
  }

  .page-pagination__next {
    margin-left: auto;
  }
}

// WHAT NEXT
.what-next {
  background: $offwhite;

  &__container {
    padding: 3rem 0;

    @include media($tab) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 70px 0;
    }

  }

  &__copy {
    margin-bottom: 20px;

    @include media($tab) {
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  &__title {
    margin-top: 0;
    letter-spacing: 1.5px;
  }

  &__info {
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__links {

    @include media($ptab) {
      display: flex;
      // justify-content: space-between;
    }
  }

  &__link {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.8em;
    border-radius: 3px;
    text-align: center;
    background: $blue;
    color: white;
    padding: 20px 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0%;
    // margin-bottom: 3rem;

    &:hover {
      background: $blue-hover;
      color: $white;
    }

    @include media($mobileOnly) {
      margin-bottom: 10px;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($ptab) {
      margin-right: 10px;
      min-width: 120px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__bottom-link {
    padding: 1rem;
    background: white;
    color: $blue;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;

    i {
      margin-right: 1rem;
      transition: transform 0.3s ease;
    }

    &:hover {
      background: $blue;
      color: white;

      i {
        transform: translateX(-0.5rem);
      }
    }
  }
}

// TIPS
.tips {
  &__title {
    color: $blue;
    font-weight: 300;
    font-size: 1.2em;
    margin-bottom: 2rem;
  }

  &__container {
    @include media($tab) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;


      > .tip {
        width: calc(50% - 1rem);
      }
    }
  }
}

.tip {
  display: flex;
  color: white;
  overflow: hidden;
  margin-bottom: 2rem;
  min-height: 20rem;

  @for $i from 1 through 6 {
    &:nth-child(#{$i}n) {
      .tip__problem {
        background-image: url(../img/eservices/tips-bgs/Tips-Tricks-#{$i}.svg);
      }
    }
  }


  .tip__intro {
    position: relative;
    flex: 1;
    width: 100%;
    min-width: 100%;
    flex-shrink: 0;
  }

  // .tip__image {
  //   position: absolute;
  //   object-fit: cover;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  // }

  .tip__problem {
    background: $blue;
    padding: 5%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    font-size: 1.2em;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: 50% 50%;

    p {
      margin-top: 0;
    }
  }

  &__problem-container {

  }

  .tip__solution {
    background: $blue-hover;
    padding: 3rem;
    min-width: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    z-index: 100;
    transition: transform 0.6s ease;
    @extend .ease-in-out-cubic;
    pointer-events: none;
    z-index: 1;

    p {
      margin: 0;
    }

    a {
      border-bottom: 2px solid $white;

      &:hover {
        color: $white;
        border-bottom-color: rgba($white, 0);
      }
    }

    span {
      text-decoration: none !important;
    }
  }

  &__trigger {
    @extend .large-link;

    margin-top: 1rem;
    font-size: 0.7em;

    @include media($ptab) {
      font-size: 0.9em;
    }


    &:hover {
      opacity: 0.8;

      i {
        transform: translateX(0.5rem)

      }
    }

    i {
      vertical-align: middle;
      margin-left: 0.5rem;
      transition: transform 0.3s ease;
    }
  }

  .tip__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  &.active {
    .tip__intro {
      pointer-events: none;
      user-select: none;
    }

    .tip__solution {
      transform: translateX(-100%);
      pointer-events: auto;
    }
  }
}

// EVENTS
.events {
  .events__container {}
}

.event-item {
  display: block;
  padding: 10%;
  background-size: cover;
  margin-bottom: 0.3rem;
  position: relative;
  color: white;

  &:hover {
    color: white;
    &::after {
      opacity: 0.8;
    }
  }

  &::after {
    content: "";
    @extend .full-size;
    transition: opacity 0.3s ease;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  .event-item__content {
    position: relative;
    z-index: 10;
    display: block;

    p {
      margin: 0;
      line-height: 1.3;
    }
  }

  .event-item__title {
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 1em;

    @include media($ptab) {
      font-size: 2em;
    }
  }

  .event-item__date {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 1rem;
  }
}

.story-slider-outer {
  margin: 50px 0;

  .story-slider-container {
    @include media($mobileOnly) {
      padding: 0;
    }
  }

  .story-slider-slide {
    position: relative;
    display: block;
    background-size: cover;
    background-position: 50% 0%;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 30px;
    height: 425px;

    &::before {
      content: '';
      @extend .full-size;
      background: rgba($black, 0.45);
      z-index: 0;
      transition: background 0.3s;
    }

    &:hover {
      color: $white;

      &::before {
        background: rgba($black, 0.4);
      }
    }
  }

  .story-card-type {
    position: absolute;
    font-weight: 700;
    text-transform: uppercase;
    top: 20px;
    left: 30px;
    letter-spacing: 1px;
    font-size: 14px;
    color: $white;
    text-shadow: 0 0 10px rgba($black, 0.1);
  }

  .story-slider-copy {
    z-index: 1;

    h2 {
      @include media($desk) {
        font-size: 21px;
      }
    }

    p {
      line-height: 1.35;
    }
  }

  .story-slider-read-more {
    @extend .large-link;
  }

  &--large {
    .story-slider-slide {
      height: 550px;
      min-height: 400px;
      max-height: 100vh;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 60px;
    }

    .story-slider-slide-container {
      position: relative;
      @extend .container;
      width: 100%;
      z-index: 1;
    }

    .story-slider-copy {
      max-width: 600px;

      h2 {
        font-size: 24px;

        @include media($desk) {
          font-size: 38px;
        }
      }
    }

    .story-slider-read-more {
      margin-top: 25px;
    }
  }

}

.story-slider-single {
  @extend .story-slider-outer;

  &__container {
    @extend .narrow-container;
  }

  .story-slider-copy {
    max-width: 450px;
  }
}


// STORY SLIDER
.story-slider-extra {
  @extend .story-slider-outer;

  &__container {
    @extend .container;
    position: relative;
  }

  .slider__pagers {
    left: 0;
    top: calc((100% - 40px)/2 - 32px) !important;
  }

  // swiper container
  &__slider {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  // swiper wrapper
  &__slides {
    display: flex;
    align-items: center;
  }

  // swiper slide
  &__slide {
    min-width: 100%;
  }

  @include media($ptab) {
    &__slide {
      min-width: calc((100% - 10px * 1)/2);
      margin-right: 10px;
    }

    &[data-number="2"] {
      .story-slider-extra__slide {
        min-width: calc((100% - 10px * 1)/2);
      }
    }
  }

  @include media($desk) {
    &:not([data-number="2"]) {
      .story-slider-extra__slide {
        min-width: calc((100% - 10px * 2)/3);
      }
    }
  }


  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid $blue;
      margin-right: 10px;
      display: inline-block;
      transition: background 0.3s;
      cursor: pointer;

      &.active, &:hover {
        background: $blue;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

section.section--banner {
	.story-cards__container {
		overflow: visible;
	}
}
.banner {
	position: relative;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
	/*background-color: #111;
	background-size: contain;
	background-position: center top;
	background-repeat: no-repeat;
	&:before {
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0);
		transition: background .5s ease-in-out;
	}
	&:hover:before {
		background: rgba(0,0,0,.8);
	}
	*/
	img {
		width: 100%;
		display: block;
	}
	.content {

		@include media($ptab) {
			position: absolute;
			//opacity: 0;
			.inner {
				position: absolute;
			}
		}
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		color: #fff;
		background: rgba(0,0,0,.8);
		//position: relative;
		z-index: 2;
		transition: opacity .5s ease-in-out;
		.inner {
			bottom: 0;
			font-size: 15px;
			font-weight: 500;
			padding: 15px;
			@include media($ptab) {
			  padding: 45px;
			  font-size: 22px;
			  margin-top: 2em;
	  	}
	  	@include media($desk) {
			  padding: 80px;
			  font-size: 26px;
			  margin-top: 5em;
			}
		}
		a {
			color: $teal;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: .06em;
			font-size: 13px;
			@include media($ptab) {
  			font-size: 18px;
			}
			padding-bottom: 4px;
			border-bottom: 2px solid rgba($teal,0);
			transition: border .25s;
			&:hover {
				border-bottom: 2px solid $teal;
			}
		}
		&.white a,
		&.mid a {
			color: #fff;
			&:hover {
				border-bottom-color: #fff;
			}
		}
		&.teal,
		&.dark {
			color: $grey;
		}
		&.dark {
			a {
				color: $body-color;
				&:hover {
					border-bottom-color: $body-color;
				}
			}
		}

		p {
			margin: 0;
			line-height: 1.25;
		    @include media($ptab) {
				max-width: 22em;
			}
			@include media($desk) {
				max-width: 16em;
			}
			& + p {
				margin-top: 1em;
			}
		}
	}
	&:hover .content {
		opacity: 1;
	}
}

// Text & Image 2-Column Block
.two-col-txt-img {
  padding: 4rem 0;
  padding-top: 6.25rem;
  position: relative;

  &.white-text {
    *:not(.button) {
      color: white;
    }
  }

  .icon {
    width: 80px;
  }

  .txt {
    @include media($mobileOnly){
      text-align: center;
    }
    @include media($tab){
      width: 65%;
      padding-right: 20px;
    }

    @include media($desk){
      width: 50%;
    }
    &.has-icon {
      padding-left: 95px;
      position: relative;

      @include media($mobileOnly){
        padding-left: 0;
      }
      .icon {
        @include media($mobileOnly){
          display: block;
          margin: 0 auto 5px;
        }
        @include media($ptab){
          position: absolute;
          top: 0;
          left: 0;
        }

      }
    }
  }

  .container {
    position: relative;
  }
  .img {
    display: none;
    @include media($tab){
      display: block;
      position: absolute;
      width: 35%;
      height: calc(100% + 10.25rem);
      top: -6.25rem;
      left: 65%;
    }

    @include media($desk){
      left: 50%;
      width: 50%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100%;
      max-width: none;
    }
  }
}
