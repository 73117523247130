$blue: #3EB1C8;
$blue-2: #2D96AF;
$blue-3: #21859F;
$teal: #58B6B2;
$teal-2: #67CAC6;
$green: #A9D362;

$beige-light: #F3F3F0;
$beige: #D5D1C3;
$beige-dark: #C7C1AB;

$blue-hover: darken($blue, 12%);
$green-hover: darken($green, 12%);

$offwhite: #F3F3F0;
$offwhite-dark: darken($offwhite, 15%);
$grey: #9B9B9B;
$grey-dark: #3E4643;

$white: #fff;
$black: #000;

$body-color: $grey-dark;

$red: #E92B17;

$transparent: rgba($white, 0);

$purple: #A1519F;
