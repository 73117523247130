html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 12px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @include media($ptab) {
    font-size: 14px;
  }


  @include media($tab) {
    font-size: 16px;
  }
}

/* Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */

/* 1 */
// .ie9 img[src$=".svg"]
//   width: 100%
// /* 2
// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
//   img[src$=".svg"]
//     width: 100%

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: 1px dotted rgba(150, 150, 150, 0.5);
}

html,
body {
  width: 100%;

  &.no-scroll {
    overflow: hidden;
  }
}

body {
  background: white;
  font-family: $font;
  line-height: 1.6;
  font-weight: 300;
  color: $body-color;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow-x: hidden;

  // font-size: 18px
  // @include media($desk)
  //   font-size: 16px

}

.screen-reader-only {
  position: absolute;
  overflow: hidden;
  width: 0px;
  height: 0px;
}

img {
  max-width: 100%;
  height: auto;

  &.aligncenter {
    display: block;
    margin: auto;
  }
}

// Global Styles
// -----------------------------------
.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.hidden {
  display: none;
}

#browser-warning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #ffc208;
  font-size: 24px;
  z-index: 1000;
  padding: 40px;
  color: #000;

  strong {
    font-weight: bold;
  }
}

.inner-wrap {
  position: relative;
  margin: 0 10.2%;
}

.narrow-wrap {
  margin: 0 26.2%;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// admin link to help people edit pages when you disable the full admin bar
.post-edit-link {
  position: fixed;
  background: #999;
  bottom: 10px;
  left: 10px;
  display: block;
  border-radius: 100px;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: background 0.2s linear;
  color: #fff;
  z-index: 10000;
  font-weight: bold;

  &:hover {
    background: darken(#999, 10);
  }
}

// HEADER
.site-header {
  position: fixed;
  z-index: 201;
  left: 0;
  padding-top: 25px;
  &.has-message-bar {
    padding-top: 0;
  }
  transition: padding-top 0.25s ease, background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  .site-header__container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include media($mobileOnly) {
      display: block;
    }
  }

  .site-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    a.button {
	  display: inline-block;
	  margin: 0 10px 0 0;
	  padding: .5rem .75rem;
	  @include media($ptab) {
	  margin: 0 1em 0 0;
		  padding: .75rem 1.5rem;
	  }
  	}
  }


  .scrolled &,
  .open-internal-nav & {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }

  .scrolled & {
    background: white;
  }

  .open-internal-nav & {
    background: $blue;
  }

  .scrolled &,
  .open-internal-nav & {
    padding-top: 0;

    // transition-delay: 0s, 0.5s, 1s
  }

  .nav-open & {
    background: transparent !important;
    padding-top: 0;
    transition: padding-top 0.25s ease, background 0.1s ease , box-shadow 0.3s ease;
    pointer-events: none;

    .internal-nav {
      pointer-events: none;
    }
  }

  .nav-open.scrolled & {
    color: white;
    background: none;
    box-shadow: none;

    // transition-delay: 0s, 0s, 0s, 0s

  }

  // .single-theme_type &,
  // .page-template-page-my-life-group & {
  //   background: $blue;
  // }

  // .scrolled.single-theme_type &,
  // .scrolled.page-child &,
  // .scrolled.page-parent & {
  //   background: white;
  //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  // }

  .scrolled.open-internal-nav & {
    background: $blue;
  }

  .nav-open.single-theme_type &,
  .nav-open.page-child &,
  .nav-open.page-parent.scrolled & {
    box-shadow: none;
    background: transparent;
    transition-delay: 0s, 0s, 0s;
  }

  .site-header__right {
    color: white;

    .page-child &,
    .single-theme_type &,
    .scrolled & {
      color: $body-color;
    }

    .nav-open & {
      color: white;
    }
  }
}

.more-nav {
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  z-index: 1000;

  .more-nav-active & {
    background: $offwhite;

    .more-nav__trigger {
      color: $body-color;
    }

    .more-nav__nav {
      opacity: 1;
      pointer-events: auto;
    }

    .more-nav__overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__trigger {
    position: relative;
    z-index: 10;
  }

  &__nav {
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    background: $offwhite;
    padding: 2rem;
    padding-top: 5rem;
    top: -0.3em;
    right: -0.3em;
    text-align: left;
    font-size: 1.2em;
    white-space: nowrap;

    a {
      display: block;
      color: $body-color;

      &:hover {
        color: $blue;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
  }
}

.search-area-trigger {
  padding: 0 0.5rem;
  background: $beige-light;
  transition: background 0.3s ease;
  color: $body-color;

  &:hover {
    background: darken($beige-light, 10%);
  }
}

// Old search area styling
// .search-area {
// display: inline-block;

// .search-area__content {
//   position: fixed;
//   z-index: 1001;
//   left: 0;
//   right: 0;
//   top: 0;
//   padding: 1.5rem 1rem;
//   background: white;
//   color: $body-color;
//   transform: translateY(-110%);
//   transition: all 0.3s ease;
//   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

//   .search-active & {
//     transform: none;
//   }
// }
// }

// .search-form {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .search-form__close {
//     cursor: pointer;
//   }

//   .search-field {
//     flex: 1;
//     margin-right: 1rem;
//     margin-left: 1rem;
//     border: 0;
//     font-size: 1.2em;
//     padding: 0.5rem;
//     border-bottom: 1px solid $grey;
//   }
// }


.search-area {
  padding: 1rem 3rem;
}

.search-form {
  display: flex;
  border: 2px solid rgba($white, 0);
  transition: border-color 0.15s;

  &.focused {
    border-color: $blue;

    .search-submit {
      color: $blue-2;
    }
  }

  .search-field, .search-submit {
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
  }

  .search-field {
    flex: 1;
    padding: 0.5rem 0.7rem;
    background: $offwhite;
    font-weight: 500;
    @include placeholder($grey, 500);
  }

  .search-submit {
    background: $offwhite;
    padding: 0.3rem 0.5rem;
    transition: background 0.15s;

    &:hover,
    &:active {
      background: darken($offwhite, 12%);
    }

    &:active {
      color: $blue-2;

      i {
        transform: translate(1px, 1px);
      }
    }
  }
}


.donate-bar {
  background: #EF3340;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  position: relative;
  padding: 1rem 0;
  font-size: 0.7em;
  max-height: 10rem;
  margin-top: rem(-25);

  .not-front & {
    display: none;
  }

  .nav-open &,
  .scrolled & {
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin-top: 0;
  }

  @include media($desk) {
    padding: 0.5rem;
    font-size: 0.9em;

    // position: absolute
    // left: 0
    // top: 0
    // width: 100%
    // transform: translateY(-100%)
  }


  p {
    margin: auto;
  }

  a {
    text-transform: uppercase;
    border-bottom: 1px solid white;
    margin-left: 1rem;

    &:hover {
      color: white;
      opacity: 0.6;
    }
  }
}

#navigation {
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 300;
  transition: transform 0.3s ease;
  pointer-events: auto !important;

  &::before {
    content: '';
    position: absolute;
    width: 320px;
    height: 41px;
    background: $blue;
    transform: translateX(-102%);
    transition: transform 0.25s ease-out;
  }

  .scrolled & {
    // box-shadow: 0px 0px 8px rgba(0,0,0,0.1)

  }

  .single-theme_type &,
  .page-child &,
  .page-parent &,
  .nav-open & {
    box-shadow: none;
  }


  .nav-open & {
    &::before {
      transform: none;
    }
  }

  .menu-trigger {
    background: $grey-dark;
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0;
    transition: background 0.3s ease;
    display: relative;
    z-index: 1;
    outline: none;

    &:hover {
      background: darken($grey-dark, 5%);
    }
  }
}

.transition {
  width: 18px;
  height: 14px;
  margin: 13px 11px;
  position: relative;

  .line {
    transition: transform 0.25s ease;
    height: 2px;
    background: white;
    position: absolute;

    &.top {
      top: 0;
      width: 50%;

      &.left {
        transform-origin: 0% 0%;
      }

      &.right {
        transform-origin: 100% 0%;
      }

      .nav-open & {
        &.left {
          transform: translate(2px, -1px) rotate(45deg) scaleX(1.25);
        }

        &.right {
          transform: translate(-2px, -1px) rotate(-45deg) scaleX(1.25);
        }
      }
    }

    &.bottom {
      bottom: 0;
      width: 50%;

      &.left {
        transform-origin: 0% 100%;
      }

      &.right {
        transform-origin: 100% 100%;
      }

      .nav-open & {
        &.left {
          transform: translate(2px, 2px) rotate(-45deg) scaleX(1.25);
        }

        &.right {
          transform: translate(-2px, 2px) rotate(45deg) scaleX(1.25);
        }
      }
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.mid {
      width: 100%;
      top: 6px;

      .nav-open & {
        transform: scaleX(0);
      }
    }
  }
}

#site-nav-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  will-change: opacity;
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none;
  cursor: pointer;
  z-index: 100;

  .ie-fixes & {
    z-index: -1;
  }

  .open-internal-nav &,
  .search-active &,
  .nav-open & {
    pointer-events: auto;
    opacity: 1;

    .ie-fixes & {
      z-index: 100;
    }
  }

  // &:hover
  //   opacity: 0.9
}

.site-name {
  overflow: hidden;
  margin: 0;
  background-color: white;
  font-size: 1rem;
  line-height: 1;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;

  a {
    position: relative;
    display: block;
    overflow: hidden;
    line-height: 0;

    svg {
      margin: 12px 20px;
      @include media($ptab) {
	      margin: 12px 30px;
      }
      max-width: 160px;
      transition: opacity 0.25s ease;
    }

    .logo-colour {
      position: relative;
    }

    .logo-white {
      position: absolute;
      opacity: 0;
    }
  }

  .nav-open &,
  .locations-open & {
    background-color: $blue;

    a {
      .logo-colour {
        position: absolute;
        opacity: 0;
      }

      .logo-white {
        position: relative;
        opacity: 1;
      }
    }
  }
}

// SITE INTERNAL NAVIGATION
.site-header-internal-nav {
  display: flex;
  align-items: center;

  // transform: translateX(102%)
  // opacity: 0
  transition: opacity 0.1s 0.2s;
  padding: 10px 15px;

  // pointer-events: none
  justify-content: space-between;

  // background: white
  width: 100%;
  color: white;

  @include media($wideDesk) {
    background: none;
  }

  .open-internal-nav &,
  .scrolled &,
  .single-theme_type &,
  .page-child & {
    pointer-events: auto;

    // transition-delay: 0.4s
    transform: translateX(0);
    opacity: 1;
    color: $body-color;
  }

  .nav-open & {
    opacity: 0;
    transition-delay: 0s;
    transition-duration: 0.1s;
    pointer-events: none;
  }

  @include media($wideDesk) {
    &.desktop-view {
      .internal-nav-mob {
        width: auto;

        .internal-nav-current {
          display: none;
        }

        .internal-nav__dropdown {
          display: none;
        }
      }

      .internal-nav {
        white-space: nowrap;
        background: none;
        min-width: 0;
        position: relative;
        width: auto;
        top: 0;
        transform: none;
        opacity: 0;
        pointer-events: auto;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;

        .scrolled & {
          opacity: 1;
          pointer-events: auto;
        }

        a {
          font-size: 13px;
          font-weight: 400;
          padding: 6px 0;
          margin-left: 15px;
          display: inline-block;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.internal-nav-title {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.5px;
  color: white;
  display: inline-flex;
  align-items: center;

  .blue-parent-link {
    color: $blue !important;

    a {
      color: $blue !important;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    padding: 6px 0;
    color: $white;

    span {
      padding-left: 5px;
    }
  }

  i {
    transition: transform 0.25s ease;
  }

  &:hover {
    i {
      transform: translateX(-0.3rem);
    }

    a {
      color: inherit;
    }
  }

  .scrolled & {
    color: $body-color;

    a {
      color: $blue;
    }
  }

  .scrolled.open-internal-nav & {
    color: white;

    a {
      color: white;
    }
  }
}

.internal-nav-mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0 5px;
  font-size: 16px;

  .internal-nav-current {
    &::before {
      content: ": ";
      font-weight: 700;
    }
  }

  .internal-nav__back {
    // margin-right: 4px;
    // margin-left: -26px;
    font-size: 17px;
    pointer-events: none;
  }

  .internal-nav__dropdown {
    transition: all 0.3s ease;
    color: white;
    cursor: pointer;

    .scrolled & {
      color: $blue;
    }

    .scrolled.open-internal-nav & {
      color: white;
    }
  }

  .open-internal-nav & {
    .internal-nav__dropdown {
      transform: rotate(180deg);
    }
  }
}

.internal-nav {
  // transform: translateY(10%);
  opacity: 0;
  position: absolute;
  min-width: 100%;
  right: 0;
  top: 100%;
  transition: opacity 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 15px 40px;
  background: white;
  font-size: 14px;

  .open-internal-nav & {
    display: block;
    transform: translateY(0);
    opacity: 1;
    box-shadow: (-0px) 6px 6px 0px rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  }

  a {
    letter-spacing: 0.2px;
    font-weight: normal;
    display: block;
    line-height: 1;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 500;
    transition: color 0.3s;

    &.current-page {
      color: $blue-2;
      position: relative;
    }
  }
}

// SITE NAVIGATION
.site-nav-drawer {
  position: fixed;
  top: 41px;
  header.site-header.has-message-bar ~ & {
    top: 76px;
    height: calc(100vh - 76px);
  }
  width: 320px;
  left: 0;
  height: calc(100vh - 41px);
  overflow-y: auto;
  z-index: 200;
  will-change: transform;
  transform: translateX(-102%);
  background: white;
  transition: all 0.25s ease-out;
  box-shadow: 2px 0 6px 1px rgba(0, 0, 0, 0.1);

  .nav-open & {
    transform: translateX(0);
  }

}

.site-nav {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 41px);

  &__top {
    flex: 1 1 auto;
  }

  &__bottom {
    background: $offwhite;
    padding: rem(40) 0;
  }

  .menu-item-container {
    display: block;
    position: relative;
  }

  .dropdown-arrow {
    display: none;
  }

  &__list {
    @extend .list-reset;

    padding: 2rem 3rem;

    > li {
      margin-top: 1.5rem;
      position: relative;

      &:first-child {
        margin-top: 0;
      }
      .site-nav__top & > .menu-item-container > a {
        color: $blue;
      }

      &.menu-item-has-children {
        &.submenu-open {
          > .menu-item-container a {
            color: $blue;
          }

          .dropdown-arrow {
            .state {
              transform: rotate(180deg);
            }
          }

          .sub-menu {
            display: block;
          }
        }

        &:hover {
          .dropdown-arrow {
            background: $offwhite;
          }
        }

        .dropdown-arrow {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 1.625em;
          padding: 0.5rem 0.5rem;
          line-height: 1;
          transition: all 0.25s ease;
          display: block;
          color: black;

          .ie-fixes & {
            bottom: 0;
          }

          &:hover {
            color: $blue;
          }

          .state {
            width: 1rem;
            transition: transform 0.25s ease;
          }
        }
      }

      a {
        font-size: em(20);
        font-weight: 500;
        margin: 0;
        display: block;
        line-height: 1.3;
      }
    }

    ul {
      @extend .list-reset;
    }

    .sub-menu {
      .current-menu-item {
        color: $blue;
      }

      li:first-child {
        padding-top: 1rem;
      }

      a {
        font-size: em(16);
        letter-spacing: 0;
        font-weight: normal;
        line-height: 1.2;
        margin-bottom: 0.5em;
        display: block;
      }

      .dropdown-arrow {
        display: none !important;
      }
    }
  }

  .e-services-nav {
    padding-top: rem(50);
    padding-bottom: rem(50);
    flex-shrink: 1;

    &__intro {
      font-size: 14px;
      margin: 0 0 rem(40);
    }
  }

}


.eservices-return {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 0.7rem 1rem;
  display: inline-block;
  transition: color 0.3s ease, opacity 0.3s ease;

  &:hover {
    i {
      transform: translateX(-0.5rem);
    }
  }

  i {
    margin-right: 1rem;
    font-size: 1.4rem;
    transition: transform 0.3s ease;
  }

  .single-theme_type & {
    // display: none

  }

  .resource-panel-active & {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }

  .scrolled & {
    color: $body-color;
  }

  .nav-open & {
    color: white;
  }
}

.sub-menu {
  display: none;

  .submenu-open & {
    display: block;
  }
}

// PAGE HEADER
.page-header {
  background-color: $blue;
  padding: 12rem 0 4rem;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
  box-shadow: inset 0 -15px 15px -15px rgba(0, 0, 0, 0.1);

  .handheld & {
    background-attachment: initial;
  }

  .page-title {
    margin: 0;
  }

  .page-lead {
    @extend .lead;

    // text-align: left
    max-width: 32em;
    margin: 0 auto;
  }

  .page-child & {
    background-color: white;
  }

  &.dark-text {
    color: $body-color;

    .page-title {
      &::after,
      &::before {
        background: $body-color;
      }
    }
  }

  &.large-cover {
    height: 85vh;
    min-height: 500px;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    align-items: center;

    .container {
      width: 100%;
    }
  }
}

// MAIN
.main-content {
  padding-top: 2rem;

  .home &,
  .find-a-centre &,
  .single-location_type &,
  .single-story_type & {
    padding-top: 0;
  }
}

// FOOTER
.site-footer {
  margin-top: auto;
  position: relative;
  transition: all 0.5s 0.3s ease;
  background: $grey-dark;
  padding: 3rem 0;
  color: white;
  font-size: 0.8em;

  &__mobile-trigger {
    margin-top: 1rem;
    display: block;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;

    @include media($ptab) {
      display: none;
    }


    i {
      vertical-align: middle;
      transition: transform 0.25s ease;

      .reveal-footer & {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    display: none;
    overflow: hidden;

    .reveal-footer & {
      display: block;
    }

    @include media($ptab) {
      display: block;
    }
  }

  &__container {
    padding-top: 1rem;

    > div {
      // margin-top: 2rem
      flex: 1;
    }

    @include media($ptab) {
      display: flex;
      align-items: top;
      justify-content: space-between;

      > div:not(:last-child) {
        margin-right: 3rem;
      }
    }


    .site-footer__sign-up {
      width: 100%;
      float: left;

      @include media($ptab) {
        max-width: 15rem;
      }
    }
  }

  &__logo {
    display: inline-block;
    transform: translateX(-10px);
  }

  &__introduction {
    margin-top: 30px;
  }

  &__contact {

    p {
      &:first-of-type {
        margin-top: 2rem;
      }
    }
  }

  .newsletter-privacy-link {
    margin-top: 15px;
  }
}


.site-footer__about {
  width: 48%;
  float: left;

  & + & {
    text-align: right;
    padding-left: 1rem;
    margin-left: 2%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  @include media($ptab) {
    float: none;
    width: auto;

    & + & {
      text-align: left;
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
    }
  }


  a {
    display: block;
  }
}

.site-footer__about-links {
  margin-top: 1rem;

  i {
    font-size: 1em;
  }
}

.social-icons {
  display: flex;
  flex-wrap: wrap;

  .site-nav-drawer & {
    margin: 1rem 3rem 2.5rem;
    // justify-content: center;
    display: block;
    text-align: center;
  }

  .site-footer & {
    @include media($mobileOnly) {
      justify-content: flex-end;
    }
  }


  span {
    font-weight: 500;
    letter-spacing: 1px;
  }

  i.fa {
    font-size: 13px;
    color: $white;

    @include media($mobileOnly) {
      font-size: 11px;
    }
  }

  .social-icon {
    background: $blue;
    border-radius: 50%;
    line-height: 0;
    padding: 8px;
    transition: background 0.25s ease;
    height: 29px;
    width: 29px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include media($mobileOnly) {
      height: 23px;
      width: 23px;
    }

    &:not(:last-child) {
      margin: 0 0.5rem 0.5rem 0;
    }

    &:hover {
      background: $blue-hover;
    }

    // img {
    //   width: 13px;
    //   height: 13px;
    // }
  }
}

// SLIDER
.card-slider {
  width: 100%;
  // overflow: hidden;
  opacity: 0;

  &.show-slider {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.slider__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .slider__title {
    margin-bottom: 0;
  }
}

.slider__see-all {
  color: $white;
  font-size: 1em;
  display: block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: $white;
    transition: transform 0.3s;
  }

  &:hover {
    color: $white;

    &::before {
      transform: scaleX(1.2);
    }
  }
  &.teal {
	  color: $blue;
	  &::before {
		  background: $blue;
	  }
  }
}

.slider__title {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: em(18);

  .single-story_type &,
  .page-template-archive-news & {
    color: $body-color;
  }
}

.slider-outer {
  position: relative;

  .slide-container {
    @extend .wide-container;

    width: 100%;
    padding: 1rem 0;
    white-space: nowrap;
    display: flex;

    // @include media($tab)

  }

  .slider__pagers {
    width: 100%;
    position: absolute;
    top: 50%;
    pointer-events: none;
    overflow: hidden;

    .slider__pagers-container {
      position: relative;
      max-width: 82rem;
      margin: 0 auto;
      pointer-events: auto;
    }

    .slider__pager {
      cursor: pointer;
      transition: opacity 0.25s ease;
      color: $teal;
      transition: all 0.25s ease;
      position: relative;
      z-index: 10;

      i {
        font-size: 4rem;
        line-height: 1;
      }

      &:hover {
        transform: none;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .slider__pager--next {
      float: right;
      transform: translateX(0.5rem);

      @include media($tab) {
        transform: translateX(-0.5rem);
      }
    }

    .slider__pager--prev {
      float: left;
      transform: translateX(-0.5rem);

      @include media($tab) {
        transform: translateX(0.5rem);
      }
    }
  }

  &__pagination {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    text-align: center;

    .swiper-pagination-bullet {
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 0.3rem;
      }

      &::before {
        content: "";
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        transition: background 0.25s ease;
        border: 1px solid $teal;
      }

      &.active,
      &:hover {
        &::before {
          background: $teal;
        }
      }
    }
  }
}

.slider-outer--white {
  @extend .slider-outer;

  .slider__pagination {
    .swiper-pagination-bullet {
      &::before {
        border: 1px solid white;
      }

      &.active,
      &:hover {
        &::before {
          background: white;
        }
      }
    }
  }
}

// CHILD PAGE NAVIGATION
.child-nav {}

.child-nav__menu {
  background: $offwhite;
  padding: 3rem 0 2rem;
  display: flex;
  justify-content: space-around;

  a {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.8rem;
    color: $blue;

    &:hover {
      color: $blue-2;
    }

    &::before {
      height: 1rem;
      width: 1rem;

      // background: url()

    }
  }
}

.signup-form {
  width: 100%;

  .signup-form__input {
    width: 100%;
    display: block;
    background: transparent;
    border: 0;
    border-bottom: 1px solid transparentize($offwhite, 0.8);
    margin-top: 0.5rem;
    padding-bottom: 0.5em;
    transition: border 0.3s ease;

    &:hover,
    &:focus {
      border-bottom: 1px solid transparentize($offwhite, 0.3);
    }
  }

  .signup-form__submit {
    @extend .large-link--blue;

    background: 0;
    border: 0;
    display: inline-block;
    margin-top: 10px;
  }
}

.global-info-btn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1.5rem;
  right: 1.5rem;
  color: white;
  font-weight: 700;
  height: 3rem;
  width: 3rem;
  background: $green;
  border-radius: 50%;
  box-shadow: 1px 1px 6px rgba(black, 0.15);
  cursor: pointer;
  transition: background 0.3s;
  user-select: none;
  -webkit-tap-highlight-color: rgba($white, 0);

  i {
    font-size: 2rem;
    font-weight: normal;
  }

  &:hover {
    background-color: darken($green, 9%);
  }

  &:active {
    background: darken($green, 15%);
  }
}

.signup-form--location {
  @extend .signup-form;

  .signup-form__input {
    background: white;
    color: $body-color;
    padding: 0.5rem;
    border-bottom-color: $offwhite-dark;
  }

  .signup-form__submit {
    @extend .large-link;
  }
}

.signup-form--page {
  @extend .signup-form;

  .signup-form__input {
    padding: 1rem;
    background: $offwhite;
  }

  .signup-form__submit {
    &:hover {
      color: $blue;
    }
  }
}

// Global emergency nav
.emergency-nav {
  position: relative;
  z-index: 300;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  .nav-open & {
    z-index: 2;
  }

  &__container {
    position: absolute;
    left: 50%;
    width: 90%;
    max-width: 650px;
    min-height: 300px;
    z-index: 3;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba($black, 0.6);
    transition: transform 0.3s;
    transform: translate(-50%, 30px);

    &::after {
      content: '';
      pointer-events: none;
      height: 20px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 20px;
    }

    @include media($tab) {
      margin: 80px 0;
    }

    .emergency-nav-open & {
      transform: translate(-50%, 0);
    }
  }

  &__inner {
    padding: 0 30px;

    @include media($ptab) {
      padding: 0 40px;
    }
  }

  &__header {
    position: relative;
    background: $blue;
    color: $white;
    padding: 30px 0;
    border-radius: 5px 5px 0 0;

    h2 {
      margin: 0;
      font-weight: 500;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__body {
    background: $white;
    padding: 40px 0;
    border-radius: 0 0 5px 5px;

    .wysiwyg {
      margin: 0;

      ul {
        margin-bottom: 35px;
      }

      p {
        margin-top: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

  }

  &__trigger {
    @extend .global-info-btn;
    z-index: 2;
  }

  &__tooltip {
    position: absolute;
    font-weight: 400;
    font-size: 1rem;
    color: $body-color;
    white-space: nowrap;
    top: 50%;
    right: 4.5rem;
    padding: 0.8rem 1.75rem;
    opacity: 0;
    transform: translate(0.25rem, -50%);
    transition: transform 0.15s, opacity 0.15s;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      background: $white;
      height: 1.5rem;
      width: 1.5rem;
      left: calc(100% - 0.75rem);
      top: 50%;
      transform: translateY(-50%) rotateZ(45deg);
      box-shadow: 2px -2px 2px rgba($black, 0.17);
    }

    &::before {
      content: '';
      @extend .full-size;
      background: $white;
      border-radius: 3px;
      box-shadow: 1px 1px 7px rgba($black, 0.3);
    }

    span {
      display: block;
      position: relative;
      z-index: 1;
      line-height: 1.5;
    }

    &.active {
      opacity: 1;
      transform: translateY(-50%);
      transition-delay: 0.15s;
    }
  }

  &__overlay {
    background: rgba(black, 0.4);
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    overflow: scroll;

    .emergency-nav-open & {
      pointer-events: auto;
    }
  }

  // &__list {
  //   @extend .list-reset;
  //   position: fixed;
  //   z-index: 2;
  //   right: calc(2rem + 18px - 12.5px);
  //   bottom: calc(5rem + 9px);
  //   color: white;

  //   @include media($ptab) {
  //     right: calc(2rem + 24px - 12.5px);
  //     bottom: calc(5rem + 12px);
  //   }
  // }

  // $emergencyNavCount: 3;

  // &__list-item {
  //   margin-bottom: 13px;
  //   opacity: 0;
  //   transform: translateY(12px) rotateZ(-7deg);
  //   transform-origin: 100% 0%;
  //   transition: transform 0.3s, opacity 0.3s;
  //   a {
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-end;
  //     &:hover {
  //       color: white;
  //     }
  //   }

  //   @for $i from 1 through $emergencyNavCount {
  //     &:nth-of-type(#{$i}) {
  //       transition-delay: ($emergencyNavCount - $i) * (150ms / $emergencyNavCount);
  //     }
  //   }
  // }

  &__item-copy {
    font-weight: 500;
    font-size: 14px;
    display: inline-flex;
    margin-right: 10px;
  }

  &__item-icon {
    display: inline-flex;
    background: $blue;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;

    i {
      font-size: 15px;
    }
  }

  .emergency-nav-open & {
    .emergency-nav__overlay {
      opacity: 1;
    }
    // .emergency-nav__list-item {
    //   opacity: 1;
    //   transform: none;
    //   @for $i from 1 through $emergencyNavCount {
    //     &:nth-of-type(#{$i}) {
    //       transition-delay: ($i - 1) * (150ms / $emergencyNavCount);
    //     }
    //   }
    // }

  }
}

#scroll-to-top {
  @extend .global-info-btn;
  bottom: 5.25rem;
  z-index: 99;
  transform: translateY(0.5rem);
  opacity: 0;
  transition: transform 0.15s, opacity 0.15s, background 0.3s;
  pointer-events: none;

  &.active {
    pointer-events: auto;
    opacity: 1;
    transform: none;
  }

  i {
    font-size: 2.75rem;
  }
}

.password-protected-form {
  label {
    display: block;
  }
}


.popup {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 35em;
	z-index: 10010;
	opacity: 0;
	transform: translateY(110%);
	transition: opacity .5s, transform .5s;
	&.visible {
		transform: translateY(0);
		opacity: 1;
	}
	button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 4px;
		min-height: 10px;
		font-size: 11px;
		margin: 0;
		border-radius: 0;
	}
	.box {
		//display: flex;
		//flex-direction: column;
		padding: 10px;
		//justify-content: space-around;
		@include media($ptab) {
			//flex-direction: row;
			//align-items: center;
			padding: 20px;
		}
		width: 100%;
		background: $purple;//$teal;
		color: #fff;
		h2 {
			margin: 0;
		}
		p {
			margin: 0;
			* + & {
				margin-top: 1em;
			}
		}
	}
}


