// .single-groups_type,
// .single-theme_type
//   .wide-container,
//   .container,
//   .narrow-container,
//   .tight-container
//     padding-left: 0
//     padding-right: 0

// E-Services Page
.eservices-header {
  background: $blue;
  padding-top: 115px;

  @include media($ptab) {
    margin-bottom: 50px;
  }

  &__container {
    // @extend .container--clean;
  }

  &__content {
    background: white;
    position: relative;
    width: 100%;
    @include media($ptab) {
      padding-top: 3rem;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    padding-top: 25px;
  }

  &__title {
    margin: 0;
    padding-top: 40px;
    font-weight: 500;
    font-size: 2.4rem;
  }

  &__intro {
    background-size: cover;
    background-position: center center;
    color: white;
    position: relative;
    overflow: hidden;

    .eservices-header__title {
      padding-top: 0;
    }

    &--no-image {
      &::before {
        display: none;
      }

      color: $body-color;
    }

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    @include media($ptab) {
      // display: flex;
      // align-items: center;

      &::before {
        display: none;
      }
    }
  }

  &__intro-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 0%;
    display: block;// may be a link or a div

    @include media($ptab) {
      // order: 2;
      position: relative;
      height: 500px;
      width: 100%;
    }

    @include media($wideDesk) {
      max-width: 1180px;
      margin: 0 auto;
    }
    span {
	    position: absolute;
	    bottom: 10px;
	    right: 10px;
	    color: $blue;
	    color: #fff;
	    font-weight: bold;
	    transition: color .25s ease-in-out;
	    z-index: 5;// required for mobile, which overlaps under the header text
    }
    &:hover span {
	    color: $blue;
    }

  }

  // &__intro-image {
  //   position: absolute;
  //   // object-fit: cover;
  //   // height: 100%;
  //   // width: 100%;
  //   // top: 0;
  //   // left: 0;
  //   min-height: 100%;
  //   min-width: 100%;
  //   width: 100%;
  //   height: auto;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%);

  //   @include media($ptab) {
  //     position: relative;
  //     max-width: 50%;
  //     margin-right: 2.5rem;
  //     min-height: auto;
  //     min-width: auto;
  //     top: 0;
  //     transform: none;
  //   }
  // }

  &__info {
    // order: 1;
    position: relative;
    z-index: 1;
    padding: 4rem 1.5rem;

    .eservices-header__title {
      margin-bottom: 1.2rem;
    }

    p {
      margin: 0;

      // @extend .lead;
      font-size: 16px;

      @include media($ptab) {
        font-size: 18px;
      }

      @include media($tab) {
        font-size: 20px;
      }
    }

    @include media($ptab) {
      color: $body-color;
      max-width: rem(1020);
      margin: 0 auto;
    }

    @include media($tab) {
      display: flex;

      h1 {
        min-width: 300px;
      }

      p {
        margin-left: 50px;
      }
    }

    @include media($desk) {
      p {
        margin-left: 100px;
      }
    }
  }

}

a.eservices-header__intro-image-container:before {
	content:'';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.2);
	transition: background .25s ease-in-out;
}
a.eservices-header__intro-image-container:hover:before {
	background: rgba(0,0,0,.05);
}

.eservices-options-trigger {
  font-size: 2em;
  line-height: 1;
  transition: all 0.3s ease;
  cursor: pointer;

  i {
    font-size: inherit;
  }
}

.eservices-options-control {
  @extend .hidden;

  &:checked {
    ~ .eservices-options {
      pointer-events: auto;
      opacity: 1;
      transform: translate(0);
    }

    ~ .eservices-options-trigger {
      transform: rotate(180deg);
    }
  }
}

.eservices-content__container,
.eservices__container {
  @extend .container--clean;
  @include media($ptab) {
    padding: 0 1rem 6rem;
  }
}

// .eservices-options
//   position: absolute
//   background: white
//   left: 0
//   right: 0
//   top: 100%
//   padding: 1rem 3rem 2rem
//   z-index: 10
//   pointer-events: none
//   opacity: 0
//   transition: 0.3s ease
//   transform: translateY(-1rem)
//   box-shadow: 0px 4px 4px rgba(0,0,0,0.1)

// .eservices-options__list
//   list-style: none
//   padding: 0
//   margin: 0
//   a
//     display: block
//     padding: 0.5rem 0

.eservice-category {
  padding: 15% 20%;
  display: block;
  text-align: center;
  margin-top: 0.75rem;
  color: white;
  font-size: 1.1em;
  position: relative;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
  }

  &:hover {
    &::before {
      opacity: 0.7;
    }
  }

  span {
    position: relative;
    z-index: 10;
  }
}

.eservices-group {
  &__container {
    @extend .container--clean;

    a {
      color: $grey-dark;
      position: relative;

      &::before {
        content: '';
        @extend .full-size;
        background: rgba($blue, 1);
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }
      span {
        position: relative;
        z-index: 10;
      }

      &:hover {
        color: $white;

        &::before {
          opacity: 1;
        }
      }
    }

    @include media($ptab) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 1rem 6rem;

      a {
        width: calc((100% - 12px) / 2);
      }
    }

    @include media($desk) {

      a {
        width: calc((100% - 12px * 2) / 3);
      }
    }
  }
}

.my-life-content {
  margin-top: 3rem;

  &__container {
    max-width: rem(1020);
    margin: 0 auto;

    @include media($ptab) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 1rem 6rem;

      a {
        width: calc((100% - 0.3rem) / 2);
      }
    }
  }
}

.my-life-group {
  padding: 10%;
  background-size: cover;
  background-position: center 35%;
  margin-bottom: 0.3rem;
  position: relative;
  text-align: center;
  display: block;
  line-height: 1.3;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    &::before {
      opacity: 0.7;
    }
  }

  &::before {
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  span {
    color: white;
    font-size: 1.2em;
    position: relative;
    z-index: 10;
    letter-spacing: 1px;
    font-weight: 500;

    @include media($ptab) {
      font-size: 1.5em;
    }
  }
}

.eservices-resource-theme {
  display: flex;
  // justify-content: flex-start;
  // align-items: flex-end;
  // text-align: center;
  padding: 24px;
  margin-top: 0.3rem;
  background-color: $beige-light;
  font-size: 1.1em;
  letter-spacing: 1px;
  background-size: cover;

  @include media($ptab) {
    margin-top: 12px;
  }

  > div {
    display: relative;
    z-index: 1;
  }

  h4 {
    font-size: 18px;
  }

  p {
    letter-spacing: 0;
    font-size: 15.5px;
    line-height: 1.4;

    @include media($ptab) {
      font-size: 16px;
    }
  }

  @include media($ptab) {
    padding: 150px 30px 50px;
    min-height: 400px;
  }

  @for $i from 1 through 6 {
    &:nth-child(#{$i}n) {
      background-image: url(../img/eservices/theme-bgs/theme-bg-#{$i}.png);

      @include media($ptab) {
        background-image: url(../img/eservices/theme-bgs-desk/theme-bg-#{$i}.svg);
      }
    }
  }
}

.resource-panel-active {
  overflow: hidden;
}

// RESOURCE THEME
.eservices-link {
  text-align: center;
  line-height: 1.2;
  // padding: 6rem 0.5rem 2rem;
  background-color: $blue;
  background-size: cover;
  font-weight: 700;
  color: white !important;

  span {
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.resource-theme__lead {

  span {
    font-size: 2.4rem;
  }

  @extend .eservices-link;
}

// Resource theme top slideshow
.header-stories {
  @extend .story-slider-outer;
  @extend .story-slider-outer--large;

  margin-top: 0;
  margin-bottom: 30px !important;

  @include media($ptab) {
    margin-top: -50px;
    margin-bottom: 50px !important;
  }

  @include media($wideDesk) {
    max-width: 1180px;
    margin: -50px auto 0;
  }

  &__slider {
    width: 100%;
    overflow: hidden;
  }

  &__slides {
    display: flex;
  }

  &__slide {
    min-width: 100%;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 1px solid $white;
      margin-right: 8px;
      display: inline-block;
      transition: background 0.3s;
      cursor: pointer;

      @include media($mobileOnly) {
        width: 10px;
        height: 10px;
      }

      &.active, &:hover {
        background: $white;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  // When there is only one slide
  &--single {

    // disable dragging
    .header-stories__slides {
      transform: none !important;
    }

    // hide bullet
    .header-stories__pagination {
      display: none;
    }
  }
}

.resource-theme__row {
  display: flex;
  justify-content: space-between;

  a {
    @extend .eservices-link;

    flex: 1;
    margin-top: 0.3rem;
    margin-right: 0.3rem;

    @include media($ptab) {
      // padding-top: 15%;
    }

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50%;
      z-index: 1;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  @include media($mobileOnly) {
    margin-bottom: 0.5rem;
  }
}

.resource-theme__row a,
.resource-theme__lead {

  position: relative;
  display: flex;
  background-size: cover;
  background-position: center top;

  span, p {
    position: relative;
    z-index: 5;
  }

  p {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    padding: 0 1rem;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &::after {
    transition: background 0.3s ease;
  }

  &:hover {
    &::after {
      background: rgba(white, 0.15);
    }
  }
}

.resource-theme__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 1;
  margin: 45px 0 16px;

  &:first-of-type {

    @include media($ptab) {
      margin-top: 0;
    }
  }

  @include media($mobileOnly) {
    font-size: 20px;
    margin: 30px 20px 10px;
  }
}

.info-resources {

  a {
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 30px 15px;

    @include media($ptab) {
      min-height: 240px;
      padding: 30px;
    }

    @include media($tab) {
      margin-right: 10px;
    }

    @include media($desk) {
      margin-right: 15px;
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}n) {
        &::before {
          background-color: rgba($blue, 0.8);
          background-image: url(../img/eservices/info-bgs/info-resources-bg-#{$i}.svg);
          background-size: cover;
          background-position: 50% 50%;
          opacity: 0.7;
        }
      }
    }
  }

  // when there are more than 3 posts/tiles
  &--wrap {

    @include media($ptabAndLess) {
      flex-wrap: wrap;

      a {
        flex: 0 1 auto;
        width: calc(50% - 0.15rem);
        margin-right: 0;
      }
    }

  }
}

.take-action {
  a {
    height: 150px;
    position: relative;

    div {
      padding: 20px;
      @extend .full-size;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      background-size: 90%;
      background-repeat: no-repeat;
      background-position: 90% 90%;
      line-height: 1.4;
    }

    &::before {
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    }

    @include media($mobileOnly) {
      justify-content: center;
    }

    @include media($ptab) {
      div {
        padding: 30px 30px 40px;
        align-items: flex-end;
      }
      height: 300px;
      // margin-right: 15px;
    }

    @include media($tab) {
      div {
        padding: 30px 40px 40px;
      }
      height: 400px;
      margin-right: 25px;
    }

    @include media($desk) {
      margin-right: 30px;
    }
  }

  &:hover {
    &::after {
    }
  }
}

.take-action__can-do {
  background: linear-gradient(180deg, #3DB0C7 0%, #2698B2 100%);

  div {
    background-image: url(../img/eservices/take-action-bgs/What-you-can-do.svg);
  }
}

.take-action__apps {
  background: linear-gradient(187.25deg, #868EC4 0%, #A785BD 100%);

  div {
    background-image: url(../img/eservices/take-action-bgs/Apps-Tools.svg);
  }
}

.take-action__support {
  background: linear-gradient(180deg, #76CEC2 0%, #53B4B6 100%);

  div {
    background-image: url(../img/eservices/take-action-bgs/Get-Support.svg);
  }
}

.resource-theme__quizzes {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: white;

  a {
    position: relative;

    &::before {
      content: '';
      @extend .full-size;
      background: rgba($white, 0.1);
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      background-size: cover;
      background-color: $blue;

      &::before {
        opacity: 1;
      }
    }
  }


  // margin-bottom: .3rem
  @include media($ptab) {
    position: relative;
    margin-top: 0.3rem;
    display: flex;
    justify-content: space-between;

    a {
      margin-top: 0;
      flex: 1;
      margin-right: 0.3rem;
      height: 200px;
      font-size: 18px;
      font-weight: 700;
      justify-content: center;
      text-align: center;
      line-height: 1.4;
      background-size: cover;

      &:nth-of-type(2n+1) {
        background-image: url(../img/eservices/quiz-bgs/Quiz-1.svg);
      }

      &:nth-of-type(2n) {
        background-image: url(../img/eservices/quiz-bgs/Quiz-2.svg);
      }

      &:last-child {
        margin-right: 0;
      }

    }
  }

  @include media($tab) {
    margin-top: 10px;
    a {
      margin-right: 10px;
    }
  }

  @include media($desk) {
    margin-top: 15px;
    a {
      margin-right: 15px;
    }
  }

}

.resource-theme__quiz {
  @extend .blue-bar-link;
  display: flex;

  margin-top: 2px;
}

// .page-template-page-apps-tools {
//   &.no-resource-theme {
//     .site-header-internal-nav {
//       display: none;
//     }
//   }
// }

// Resource Panel
.resource-panel {
  // position: fixed;
  // z-index: 98;
  // top: 0;
  // left: 0;
  width: 100%;
  // transform: translateY(-1rem);
  // opacity: 0;
  // pointer-events: none;
  // transition: all 0.5s ease;
  background: white;
  // height: 100vh;
  // overflow-y: auto;

  // display: none
  &.active {
    // display: block
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }

  &__header {
    background-size: cover;
    background-position: center center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-left: 2rem;
    padding-right: 2rem;

    .narrow-container {
      margin-top: auto;
    }
  }

  &__title {
    margin-top: auto;
    margin-bottom: 2rem;
    font-family: $font-amatic;
    text-align: center;
    color: white;
    font-size: 14vmin;
    line-height: 1;
    text-transform: uppercase;

    @include media($desk) {
      font-size: 5em;
    }
  }

  &__lead {
    @extend .lead;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__learn-more {
    position: relative;
    text-align: center;
    margin-top: 2rem;
    bottom: 2rem;
    left: 0;
    display: block;
    cursor: pointer;
    line-height: 0;

    &:hover {
      i {
        transform: translateY(0.5rem);
      }
    }

    span {
      display: block;
      font-size: 0.8em;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    i {
      font-size: 3rem;
      transition: transform 0.3s;
    }
  }
}

.resource-content {
  padding: 4rem 0 2rem;
  &__header {
    padding: 1rem;
  }

  &__title {
    color: $blue;
    font-weight: 300;
    font-size: 1.3em;
  }

  .what-next {
    margin-bottom: -2rem;
  }
}

.resource-group {
  margin-bottom: 0.5rem;

  &__trigger {
    background: $grey;
    color: white;
    text-align: center;
    margin: 0;
    height: 240px;
    padding: 0 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.3s, background-position 0.3s;

    div[data-expand].active & {
      height: 150px;
      // background-position: 50% 20%;

      @include media($ptab) {
        height: 200px;
      }
    }

    @include media($ptab) {
      height: 400px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 10;
      line-height: 1.1;
      display: block;
      font-size: 18px;

      @include media($ptab) {
        font-size: 28px;
        letter-spacing: 2px;
      }
    }

    i {
      font-size: 24px;
      margin-top: 0.75rem;
      display: block;
      transition: transform 0.5s ease;
      @extend .no-select;

      @include media($ptab) {
        font-size: 30px;
      }
    }
  }

  &__content {
    // display: none;
    overflow: hidden;
    height: 0;

    &__wrap {
      padding: 2.5rem 1rem;

      @include media($tab) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .resource-group__item {
          width: calc(50% - 1rem);
          flex-shrink: 0;
        }
      }
    }
  }

  &.active {
    .resource-group__trigger {
      i {
        transform: rotate(135deg);
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-image {
    width: 35%;
    margin-right: 1em;

    img {
      width: 100%;
    }
  }

  &__item-info {
    flex: 1;

    a {
      display: block;
    }
  }

  &__item-store-links {
    margin-top: 10px;

    @include media($ptab) {
      display: flex;
    }
  }

  &__item-store-link {

    a {
      width: 80%;
      max-width: 200px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &--apple {

      @include media($ptab) {
        flex-basis: calc(42/84.3 * 100%);

        a {
          width: 90%;
        }
      }

    }

    &--google {

      @include media($mobileOnly) {
        margin-top: 10px;
      }

      @include media($ptab) {
        flex-basis: calc((1 - 42/84.3) * 100%);

        a {
          width: 100%;
        }
      }

    }

  }

  &__item-title {
    @extend .featured-experience__title;
  }

  &__item-content {
    p {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  &__item-more {
    color: $blue;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1rem;
    text-transform: uppercase;

    i {
      font-size: 1em;
      margin-left: 1rem;
      vertical-align: middle;
    }
  }
}

.resource-group--apps {
  @extend .resource-group;

  .resource-group__trigger {
    // background-image: url(../img/eservices/resource-panel/apps.jpg);
  }
}

.resource-group--quizzes {
  @extend .resource-group;

  .resource-group__trigger {
    // background-image: url(../img/eservices/resource-panel/quizzes.jpg);
  }
}

.resource-group--websites {
  @extend .resource-group;

  .resource-group__trigger {
    // background-image: url(../img/eservices/resource-panel/websites.jpg);
  }
}

.resource-group--games {
  @extend .resource-group;

  .resource-group__trigger {
    // background-image: url(../img/eservices/resource-panel/games.jpg);
  }
}

// SELF CARE
.self-care-tools {
  .resource-panel__header {
    background-image: url(../img/eservices/resource-panel/self-care-bg.jpg);
  }
}

// LIVED EXPERIENCE
.lived-experiences {
  .resource-panel__header {
    background-image: url(../img/eservices/resource-panel/headers/lived-experience.jpg);
  }

  &__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.featured-experience {
  margin-bottom: 3rem;

  @include media($ptab) {
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &__image {
      width: calc(50% - 2rem);
      flex-shrink: 0;
      margin-right: 3rem;
      max-height: 35vh;

      img {
        width: 100%;
      }
    }
  }


  &__image {
    img {
      width: 100%;
      display: block;
    }
  }

  &__content {
    padding-top: 2rem;

    @include media($ptab) {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: 300;
    margin: 0;
    letter-spacing: 0.5px;

    @include media($ptab) {
      .featured-experience & {
        font-size: 1.5em;
      }
    }
  }

  &__excerpt {}

  &__more {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 0.8em;
    display: block;
    margin-top: auto;

    &:hover {
      i {
        transform: translateX(50%);
      }
    }

    i {
      transition: transform 0.3s ease;
      font-size: 1.3em;
      margin-left: 1rem;
    }
  }
}

.lived-experiences__stories {

  @include media($ptab) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .lived-experience-story {
      width: calc(50% - 1rem);
      flex-shrink: 0;
    }
  }
}

.lived-experience-story {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @extend .resource-group;
}

// QUIZZES
.quiz-main {
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  background: $blue;
  padding-top: 8rem;
  position: relative;
  overflow: hidden;
}

.quiz-header {
  background: $blue;
  color: white;
  padding-bottom: 2rem;

  .quiz-panel__container {
    @extend .narrow-container;
  }
}

.quiz-pagination {
  margin-top: 2rem;
  padding: 1rem;
  background: $offwhite;
  text-align: center;
  display: none;

  .show-pagination & {
    display: block;
  }

  &__bullet {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    display: inline-flex;

    &::before {
      content: "";
      border: 1px solid $grey;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: inline-block;
    }

    &.active {
      &::before {
        background-color: $grey;
      }
    }
  }
}

.quiz-pagers {
  display: flex;
  justify-content: space-between;
  background: $green;
  margin-top: auto;

  &__pager {
    width: 50%;
    padding: 1rem;
    background: $green;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;

    i {
      font-size: 1.3em;
    }

    &:hover {
      background: $green-hover;
    }
  }

  &__pager--back {
    text-align: left;
  }

  &__pager--next {
    text-align: right;
    display: none;

    [data-quiz-value] & {
      display: block;
    }
  }
}

.quiz-questions {
  overflow: hidden;
  position: relative;
}

.panels-wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;

  .show-pagination & {
    background: $offwhite;
  }
}

.quiz-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // transform: translateY(-1rem);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  height: 100%;

  &__container {
    @extend .tight-container--clean;

    padding-top: 2rem;
    width: 100%;
    padding-bottom: 2rem;
    margin-top: auto;
    margin-bottom: auto;

    &[data-current="false"] {
      // display: none;
    }

    a {
      &:hover {
        color: $white;
        border-bottom: 1px solid $white;
      }
    }

    .expanders {
      padding: 0;
      p {
        a:not(.quiz-next-steps__button) {
          border-bottom: 1px solid rgba($white, 0);

          &:hover {
            color: $blue;
            border-bottom: 1px solid $blue;
          }
        }
      }
    }

  }

  &.transitioning {
    position: absolute;
  }

  &.active {
    pointer-events: auto;
    position: relative;
    opacity: 1;
    height: auto;

    // + .quiz-panel
    //   transition: none
    //   transform: translateX(105%)

  }

  &.question {
    background: $offwhite;
  }

  &[data-has-fu] {
    .subq {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.question {

  &__text {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1.2em;
    margin-bottom: 1rem;
  }

  &__additional {
    margin-bottom: 2rem;
  }

  .answer-btn {
    display: block;
    border-radius: 3px;
    text-align: center;
    background: $white;
    margin-bottom: 8px;
    padding: 25px 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    letter-spacing: 0.5px;
    font-size: 15px;
    user-select: none;
    cursor: pointer;

    &[data-open-ended] {
      span {
        // font-style: italic;
      }

      .open-ended-form {
        display: none;

        &.active {
          display: block;
        }
      }

      input, button {
        display: block;
        appearance: none;
        outline: none;
        font-size: 16px;
        border-radius: 3px;
        margin: 15px auto 0;
        width: 90%;
        background: $white;
        padding: 10px 15px;
        line-height: 1;
        color: $body-color;
      }

      input {
        border: none;
      }

      button {
        &:hover {
          background: $offwhite;
        }

        &:active {
          background: $offwhite-dark;
        }
      }
    }

    &:active,
    &.selected {
      background: $blue;
      color: $white;
    }

    @include media($ptab) {
      padding: 35px 15px;
      font-size: 16px;
    }
  }

  .question-answers__answer {
    margin-bottom: 0.5rem;
    display: block;
    width: 100%;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    padding: 10%;
    transition: all 0.3s ease;

    &.active {
      background: $blue;
      color: white;
    }
  }
}

@mixin makelongshadow($color, $size, $amount) {
  $val: 5px 0 0 $size $color;

  @for $i from 6 through $amount {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  box-shadow: #{$val};
}

.range {
  margin-bottom: 3rem;

  .range__input {
    -webkit-appearance: none;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 0;
    overflow: hidden;
    background: none;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 18px;
      cursor: pointer;
      background: $blue;
    }

    &::-moz-range-track {
      width: 100%;
      height: 18px;
      cursor: pointer;
      background: $blue;
    }

    &::-webkit-slider-thumb {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: $blue-hover;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;

      @include makelongshadow(white, -8px, 700);
    }

    &:focus::-webkit-slider-runnable-track {
      background: $blue;
    }

    &::-moz-range-thumb {
      height: 30px;
      width: 30px;
      border-radius: 12px;
      background: $blue-hover;
      cursor: pointer;
      margin-top: -10px;

      @include makelongshadow(white, -8px, 240);
    }

    &::-ms-track {
      width: 100%;
      height: 13.3px;
      cursor: pointer;
      background: rgba($white, 0);
      border-color: rgba($white, 0);
      color: rgba($white, 0);
    }

    &::-ms-fill-lower {
      background: #ff68ca;
      border: 2.3px solid #dbd58f;
      border-radius: 50px;
      box-shadow: 1.4px 1.4px 1.7px #ffb2df, 0px 0px 1.4px #ffccea;
    }

    &::-ms-fill-upper {
      background: #ff91d8;
      border: 2.3px solid #dbd58f;
      border-radius: 50px;
      box-shadow: 1.4px 1.4px 1.7px #ffb2df, 0px 0px 1.4px #ffccea;
    }

    &::-ms-thumb {
      box-shadow: 4.1px 4.1px 7.2px #27aaff, 0px 0px 4.1px #41b4ff;
      border: 5px solid #ffcdff;
      height: 30px;
      width: 30px;
      border-radius: 12px;
      background: #ffff7d;
      cursor: pointer;
      height: 13.3px;
    }

    &:focus::-ms-fill-lower {
      background: #ff91d8;
    }

    &:focus::-ms-fill-upper {
      background: #ffbae6;
    }
  }

  .range__info {
    text-align: center;
    font-size: 1.3em;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1px;
    display: none;

    &.active {
      display: block;
    }
  }
}

.dial {
  width: 100%;
  position: relative;
  border: 1em solid $beige;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  margin-top: 3rem;

  &::before {
    content: "";
    display: inline-block;
    padding-bottom: 100%;
  }

  &__question {
    position: absolute;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background: $blue;
    border-radius: 50%;
    transform-origin: center center;
    transition: transform 0.5s ease;

    span {
      transition: opacity 0.5s ease;
      opacity: 0;
      font-weight: 500;
      color: white;
      display: block;
      line-height: 1.8rem;
    }

    &:nth-of-type(1) {
      bottom: 100%;
      margin-right: -0.9rem;
      right: 50%;
    }

    &:nth-of-type(2) {
      top: 50%;
      left: calc(100% - 0.2rem);
      margin-top: -0.9rem;
    }

    &:nth-of-type(3) {
      top: calc(100% - 0.2rem);
      left: 50%;
      margin-left: -0.9rem;
    }

    &:nth-of-type(4) {
      right: calc(100% - 0.3rem);
      margin-top: -0.9rem;
      top: 50%;
    }

    &.active {
      transform: scale(2);

      span {
        opacity: 1;
      }
    }
  }

  &__info {
    text-align: center;
    font-size: 1.3em;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1px;
    display: none;

    &.active {
      display: block;
    }
  }
}

.quiz-results {
  color: white;
  background-color: $blue;

  .quiz-panel__container {
    @extend .narrow-container;
  }
}

.quiz-results-info__result {
  display: none;

  &.active {
    display: block;
  }
}

.quiz-footer {
  background: $green;
  display: flex;
  color: $white;
  margin-top: auto;

  &__btn-container {
    flex-basis: 50%;
  }

  &__btn {
    padding: 15px;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    line-height: 1;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: $green-hover;
    }

    i {
      padding: 0 5px;
      font-size: 18px;
      pointer-events: none;
      transition: transform 0.3s;
    }

    &--back {
      justify-content: flex-start;

      &:hover {
        i {
          transform: translateX(-5px);
        }
      }
    }

    &--forward {
      justify-content: flex-end;

      &:hover {
        i {
          transform: translateX(5px);
        }
      }
    }
  }
}

.quiz-next-steps {


  &__buttons {
    margin: 30px 0 0;

    > div {

      @include media($ptab) {
        display: flex;
        justify-content: space-between;
      }
    }

    a {
      display: inline-flex;
      font-weight: 700;
      text-transform: uppercase;
      background: $green;
      color: $white;
      padding: 1rem;
      text-align: center;
      justify-content: center;
      align-items: center;
      line-height: 1.4;
      user-select: none;
      font-size: 14px;
      letter-spacing: 1px;
      border-radius: 3px;
      border-bottom: none !important;
      outline: none;

      &:hover {
        text-decoration: none;
        background: $green-hover;
      }

      @include media($mobileOnly) {
        width: 100%;
        margin-bottom: 13px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include media($ptab) {
        margin-right: 10px;
        flex: 1 0 0%;

        &:last-of-type {
          margin-right: 0;
        }
      }

      @include media($tab) {
        font-size: 16px;
      }
    }
  }
}

.quiz-prompt {
  position: absolute;
  padding: 1rem;
  font-size: 0.8em;
  color: $red;
  font-weight: 500;
  background: white;
  border-radius: 4px;
  margin: 1rem;
  text-align: center;
  top: 0;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, 1rem);
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .quiz-prompt__content {}
}

// ARTICLE
.single-article_type {
  .page-cover {
    flex-direction: column;

    .narrow-container {
      margin-top: auto;
    }

    &__title {
      font-family: $font-amatic;
      font-size: 14vmin;

      @include media($desk) {
        font-size: 5em;
      }
    }

    &__scroll-prompt {
      position: relative;
      left: 0;
      text-align: center;
      font-weight: 500;
      font-size: 0.8em;
      text-transform: uppercase;
      display: block;
      letter-spacing: 1px;
      line-height: 0;
      margin-top: 2rem;

      span {
        display: block;
      }

      i {
        transform: none;
      }

      &:hover {
        i {
          transform: translateY(0.5rem);
        }
      }
    }
  }
}

$tile-gap: 12px;

.tiles {

  font-size: 18px;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 0.3rem;

  &__tile {
    position: relative;
    display: flex;
    background-size: cover;
    background-position: 50% 30%;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    padding: 3rem;
    margin-bottom: 0.3rem;
    line-height: 1.3;
    background-color: $blue;

    &::before {
      content: '';
      @extend .full-size;
      background: rgba($grey-dark, 0.4);
      transition: background 0.3s;
    }

    & > * {
      position: relative;
    }

    &:hover {
      color: $white;

      &::before {
        background: rgba($grey-dark, 0.3);
      }
    }


    &--graphic {
      &::before {
        background: none;
      }

      &:hover {
        &::before {
          background: rgba($white, 0.1);
        }
      }
    }
  }

  @include media($ptab) {
    width: 100%;
    max-width: rem(1020);
    margin: 20px auto 50px;
    padding: 0 1.5rem;

    .tiles__container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .tiles__tile {
      margin-bottom: $tile-gap;
    }
  }

  @include media($desk) {
    padding-left: 1rem;
    padding-right: 1rem;
  }


  &--top {
    @include media($ptab) {
      font-size: 24px;

      .tiles__tile {
        min-height: 300px;
        width: calc((100% - #{$tile-gap}) / 2);

        &:nth-of-type(1) {
          flex-basis: 100%;
        }

        &[data-number="2"],
        &[data-number="4"],
        &[data-number="6"],
        &[data-number="8"],
        &[data-number="10"] {
          .tiles__tile {
            &:nth-of-type(1) {
              width: calc((100% - #{$tile-gap}) / 2);
            }
          }
        }
      }
      &[data-number="2"] .tiles__tile:nth-of-type(1) {
        flex-basis: auto;
      }


    }

    @include media($tab) {
      .tiles__tile {
        min-height: 420px;
      }
    }

  }

  &--blue {
    .tiles__tile {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}n) {
          background-image: url(../img/eservices/support-bgs/Blue-#{$i}.jpg);
        }
      }
    }
  }

  &--green {
    .tiles__tile {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}n) {
          background-image: url(../img/eservices/support-bgs/Green-#{$i}.jpg);
        }
      }
    }
  }

  &--teal {
    .tiles__tile {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}n) {
          background-image: url(../img/eservices/support-bgs/Teal-#{$i}.jpg);
        }
      }
    }
  }

  &--second {
    @include media($ptab) {

      .tiles__tile {
        width: calc( (100% - #{$tile-gap} * 2) / 3 );
        min-height: 240px;
      }

      &[data-number="1"] {
        .tiles__tile {
          flex-basis: 100%;
          min-height: 360px;
        }
      }

      &[data-number="2"],
      &[data-number="4"],
      &[data-number="6"] {
        .tiles__tile {
          width: calc( (100% - #{$tile-gap}) / 2 );
          min-height: 360px;
        }
      }

      &[data-number="5"] {
        .tiles__tile {
          &:nth-of-type(4),
          &:nth-of-type(5) {
            width: calc( (100% - #{$tile-gap}) / 2 );
            min-height: 360px;
          }

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            width: calc( (100% - #{$tile-gap} * 2) / 3 );
            min-height: 240px;
          }
        }
      }

      &[data-number="7"] {
        .tiles__tile {
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(6),
          &:nth-of-type(7) {
            width: calc( (100% - #{$tile-gap}) / 2 );
            min-height: 360px;
          }
        }
      }

      &[data-number="8"] {
        .tiles__tile {
          &:nth-of-type(4),
          &:nth-of-type(5) {
            width: calc( (100% - #{$tile-gap}) / 2 );
            min-height: 360px;
          }
        }
      }
    }
  }

}


// STORY

.story-learn-more {
  margin: 50px 0;

  &__container {
    width: 100%;

    @include media($ptab) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__tile {
    background-color: $blue;
    position: relative;
    color: $white;
    background-size: cover;
    background-position: 50% 50%;
    padding: 20px 40px;
    min-height: 80px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    display: none;
    margin-right: 10px;

    &:nth-of-type(2) {
      margin-right: 0;
    }

    &:nth-of-type(1), &:nth-of-type(2) {
      display: inline-flex;
    }

    @include media($mobileOnly) {
      width: 100%;
      margin-bottom: 15px;
    }

    &::before {
      content: '';
      @extend .full-size;
      background: rgba($white, 0.1);
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    span {
      position: relative;
      max-width: 320px;
    }

    &:hover {
      color: $white;
    }

    &:nth-of-type(3n+1) {
      background-image: url(../img/eservices/support-bgs/Blue-1.jpg);
    }

    &:nth-of-type(3n+2) {
      background-image: url(../img/eservices/support-bgs/Blue-2.jpg);
    }

    &:nth-of-type(3n) {
      background-image: url(../img/eservices/support-bgs/Blue-3.jpg);
    }
  }

  @include media($ptab) {
    .story-learn-more__tile {
      min-height: 150px;
      flex-basis: 100%;
    }

  }

  @include media($tab) {
    .story-learn-more__tile {
      min-height: 240px;
    }
  }

  &[data-number="1"] {
    .story-learn-more__tile {
      flex-basis: 50%;
    }
  }

}



// MY COMMUNITY
.page-template-page-my-community {
  .eservices-header__lead {
    padding: 2rem 0 3rem;
  }
}

.page-template-page-community-services,
.page-template-page-program {
  .site-header {
    padding-top: 0 !important;
    background: $white;
    box-shadow: 0 0 8px 0 rgba($black, 0.3);
  }
}

.community-program {

  &__header {
    background: $blue;
    color: $white;
    position: relative;

    &::before {
      content: '';
      @extend .full-size;
      background-image: url(../img/eservices/quiz-bgs/Quiz-2.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 90% 50%;
      opacity: 0.5;
    }
  }

  &__header-container {
    margin-top: 41px;
    position: relative;
    padding-top: 85px;
    padding-bottom: 50px;

    h1 {
      text-align: center;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      letter-spacing: 0;
      margin: 0;
    }

    @include media($desk) {
      padding-top: 90px;
      padding-bottom: 70px;
    }
  }

  &__back {
    color: $white;
    position: absolute;
    top: 30px;
    left: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.5px;
    display: inline-flex;
    align-items: center;

    &:hover {
      i {
        transform: translateX(-5px);
      }
    }

    i {
      pointer-events: none;
      font-size: 18px;
      transition: transform 0.3s;
    }

    span {
      padding-left: 6px;
    }

    &:hover {
      color: $white;
    }
  }

  &__content {
    padding: 80px 0;
    // display: flex;
  }

  &__content-container {
    width: 100%;
    @include media($desk) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    @include media($desk) {
      // display: flex;
      width: calc((100% - 50px) / 2);
    }
  }

  &__description {
    p {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 20px;

      @include media($tab) {
        font-size: 26px;
      }
    }

    padding-bottom: 20px;
  }

  &__buttons-container {
    margin-bottom: 60px;

    @include media($ptab) {
      display: flex;
      // justify-content:
    }
  }

  &__button {
    color: $white;
    background: $green;
    padding: 15px;
    display: inline-flex;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    user-select: none;

    @include media($mobileOnly) {
      width: 100%;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($ptab) {
      flex-grow: 1;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    span {
      font-weight: 700;
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
    }

    &:hover {
      color: $white;
      background: $green-hover;
    }
  }

  &__extra-list {
    line-height: 1.5;
    h3 {
      margin: 0 0 5px;
      font-size: 18px;
    }

    p {
      margin: 0 0 35px;
    }

    ul {
      @extend .list-reset;
    }
  }
}

.program-map-container {
  margin-left: -2rem;
  margin-right: -2rem;
  width: calc(100% + 4rem);
  height: 300px;
  margin-bottom: 50px;
  margin-top: 50px;

  @include media($desk) {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 400px;
  }
}

#program-map {
  width: 100%;
  height: 100%;
}

.my-community {
  position: relative;
  padding-top: 41px;
  min-height: 100vh;

  &__loading {
    @extend .full-size;
    background: rgba($grey-dark, 1);
    z-index: 201;
    display: flex;
    color: $white;
    transition: opacity 0.3s;

    @include media($desk) {
      align-items: center;
      justify-content: center;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;

      .my-community__loading-spinner {
        transform: scale(0);
      }
    }
  }

  &__loading-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);

    @include media($desk) {
      top: auto;
      transform: none;
      position: relative;
    }
  }

  &__loading-spinner {

    margin: 0 auto;
    width: 70px;
    text-align: center;
    user-select: none;
    transition: transform 0.3s;

    > div {
      width: 16px;
      height: 16px;
      background: $white;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &.bounce1 {
        animation-delay: -0.32s;
      }

      &.bounce2 {
        animation-delay: -0.16s;
      }
    }

    @at-root {
      @keyframes sk-bouncedelay {
        0%, 80%, 100% {
          transform: scale(0);
          opacity: 0;
        }
        40% {
          transform: scale(1.0);
          opacity: 1;
        }
      }
    }
  }

  &__loading-heading {
    font-size: 16px;
    text-align: center;
    margin: 30px auto 0;
    line-height: 1.5;
    letter-spacing: 0;

    @include media($mobileOnly) {
      max-width: 250px;
      margin-top: 20px;
    }
  }

  @include media($desk) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 500px;
    flex-direction: row;
  }

  .map-container {
    // height: calc(100vh - 41px);
    height: 400px;
    width: 100%;
    position: relative;

    @include media($tab) {
      // width: calc(100% - 400px);
    }

    @include media($tabAndLess) {
      position: absolute;
      z-index: 1;
      max-width: 100vw;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 3px;
        background: rgba($black, 0.12);
      }
    }

    @include media($desk) {
      order: 2;
      height: auto;
      min-height: 400px;
      width: calc(100% - 400px);
    }

    @include media($wideDesk) {
      width: calc(100% - 550px);
    }

    #community-map {
      height: 100%;
      width: 100%;
      user-select: none;
    }

    #closest-to-me {
      position: absolute;
      top: 1.5rem;
      outline: none;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid $white;
      box-shadow: 1px 1px 8px rgba($black, 0.3);
      border-radius: 100%;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      display: none;

      &.active {
        display: block;
      }

      &:hover {
        // background: $green-hover;
      }

      @include media($tab) {
        border-radius: 3px;
        height: 50px;
        width: auto;
        padding: 0 20px;
      }

      span {
        margin-right: 6px;
        display: none;

        @include media($tab) {
          display: inline;
        }
      }
    }
  }


  &__page {
    @extend .my-life-group;

    flex-shrink: 0;
    margin-bottom: 0.3rem;
    width: calc((100% - 0.3rem) / 2);
    padding: 25% 5% 10%;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;

    &:first-child {
      padding: 20% 15%;
      width: 100%;
    }
  }

  .map-form-container {
    margin: 15px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media($ptab) {
      margin-top: 20px;
    }
  }
}

.community-input {
  height: 40px;
  font-size: 15px;
  padding: 0 15px;
  outline: none;
  font-weight: 500;
  box-shadow: none;
  appearance: none;
  border-radius: 3px;
  overflow: hidden;

  @include media($ptab) {
    font-size: 16px;
    height: 50px;
  }

  @include media($tabAndLess) {
    box-shadow: 0 0 8px rgba($black, 0.1);
  }
}

// community map filters
.cmf {
  background: $white;
  overflow-y: scroll;
  display: none;

  @include media($tabAndLess) {

  }

  &.active {
    display: block;
  }

  @include media($tab) {
    order: 1;
    flex-basis: 400px;
  }

  @include media($desk) {
    &::-webkit-scrollbar-track {
      background-color: $offwhite;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $offwhite;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darken($offwhite-dark, 8%);
    }
  }

  @include media($wideDesk) {
    flex-basis: 550px;
  }

  &__header {
    position: relative;
    padding-bottom: 30px;

    &::before {
      content: '';
      @extend .full-size;
      display: none;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    @include media($tabAndLess) {
      background-color: $blue;
      color: $white;

      &::before {
        display: block;
        background-image: url(../img/eservices/quiz-bgs/Quiz-2.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 90% 50%;
        opacity: 0.5;
      }
    }
  }

  &__container {
    @extend .container;

    @include media($desk) {
      padding-left: 30px;
      padding-right: 30px;
    }

    // &:after {
    //   display: none;
    // }
  }

  &__intro {
    padding-top: 30px;

    h1 {
      letter-spacing: 0.5px;
      margin-top: 0;

      @include media($desk) {
        font-size: 35px;
        font-weight: 500;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      @include media($tabAndLess) {
        border-bottom-color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &__total-results {
    background: $blue-2;
    color: $white;
    font-weight: 700;
    padding: 8px 0;
    text-align: center;
    display: none;

    @include media($tabAndLess) {
      // margin-top: 400px;
      box-shadow: inset 0 3px 0 rgba($black, 0.12);
    }

    &.active {
      display: block;
    }
  }

  &__dropdowns {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    & > * {
      width: calc((100% - 10px) / 2);
    }

    .cities {
      input {
        @extend .community-input;
        width: 100%;
        background: $green;
        border: 1px solid $green;
        color: $white;

        @include placeholder($white, 500, 1);

        &:focus, &:active {
          border-color: $blue-hover;
          background: $white;
          color: $blue-hover;
        }
      }
    }

    .categories {
      position: relative;
      display: hidden;

      &.show-select {
        display: block;
      }

      &.is-open {
        .categories__dropdown {
          display: block;
        }
      }

      &--mobile {
        .categories__select {

        }
      }

      &__select {
        @extend .community-input;
        display: flex;
        align-items: center;
        background: $green;
        border: 1px solid $green;
        color: $white;
        cursor: pointer;
        width: 100%;

        span {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          pointer-events: none;
        }
      }

      &__dropdown {
        position: absolute;
        top: 100%;
        z-index: 1;
        background: $white;
        border: 1px solid $blue-hover;
        border-top: 0;
        box-shadow: -1px 1px 6px rgba($black, 0.1);
        border-radius: 0 0 3px 3px;
        width: 100%;
        display: none;
        margin-bottom: 50px;
        padding: 5px 0;
      }

      &__dropdown-item {
        line-height: 1;
        margin: 0;
        padding: 6px 15px;
        color: $body-color;
        line-height: 1.3;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;

        @include media($tab) {
          font-size: 16px;
        }

        &:hover {
          background: rgba($blue, 0.25);
        }
      }
    }
  }

  .filtered-by {
    display: flex;
    flex-wrap: wrap;

    &[city],
    &[category],
    &[keyword],
    &[closest] {
      margin-top: 8px;
    }

    .filter-tag {
      display: inline-flex;
      align-items: center;
      font-size: 11px;
      background: $blue-hover;
      padding: 3px 9px 3px 11px;
      border-radius: 100px;
      margin-right: 8px;
      font-weight: 500;
      color: $white;
      cursor: pointer;
      margin-top: 8px;

      i {
        font-size: 11px;
        margin-left: 3px;
      }

      @include media($desk) {
        background: $grey;
        font-size: 12px;

        i {
          font-size: 12px;
        }
      }
    }
  }

  &__lists {

    .top-category-container {
      margin-bottom: 3px;

      &:first-of-type {
        .top-category-header {
          border-top: 0;
        }
      }
    }

    .top-category-header {
      @extend .cmf__container;
      border-top: 3px solid $blue-2;

      padding: {
        top: 15px;
        bottom: 15px;
      }
      background: $blue;
      color: $white;

      h3 {
        font-weight: 500;
        margin: 0;

        @include media($desk) {
          font-size: 18px;
        }
      }
    }

    .sub-categories-container {
      @extend .cmf__container;
      padding: {
        top: 8px;
        bottom: 8px;
      }
    }

    .sub-category-container {
      border-bottom: 2px solid rgba($offwhite-dark, 0.6);

      &:last-of-type {
        border: none;
      }

      &.active {
        border: none;

        .sub-category-header {
          .sub-category-toggle {
            span {
              &:first-of-type {
                transform-origin: 0% 0%;
                transform: translateY(-4px) rotate(40deg) scaleY(1.5);
              }

              &:last-of-type {
                transform-origin: 100% 0%;
                transform: translateY(-4px) rotate(-40deg) scaleY(1.5);
              }
            }
          }
        }
      }
    }

    .sub-category-header {

      position: relative;
      cursor: pointer;

      .sub-category-toggle {
        position: absolute;
        height: 24px;
        width: 24px;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.3s;

        span {
          position: absolute;
          bottom: 0;
          background: $grey-dark;
          display: block;
          width: 50%;
          top: 50%;
          height: 1px;
          transition: transform 0.5s, transform-origin 0.5s;
          @extend .ease-power;

          // right segment
          &:first-of-type {
            left: 50%;
            transform-origin: 0% 100%;
            transform: translateY(4px) rotate(-40deg) scaleY(1.5);
          }

          // left segment
          &:last-of-type {
            right: 50%;
            transform-origin: 100% 100%;
            transform: translateY(4px) rotate(40deg) scaleY(1.5);
          }
        }
      }

       h4 {
        font-weight: 500;
        margin: 0;
        padding: 12px 5px;
        text-transform: none;
        letter-spacing: 0;

        span {
          font-weight: 300;
          margin-left: 8px;
          color: $grey-dark;
        }
      }
    }

    .locations-container {
      // display: none;
      height: 0;
      overflow: hidden;
    }

    .show-more-btn {
      background: $green;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      text-align: center;
      padding: 10px;
      margin-bottom: 10px;
      transition: background 0.3s;
      border-radius: 3px;
      cursor: pointer;

      &.inactive {
        display: none;
      }

      &:hover {
        background: $green-hover;
      }
    }

    .location-container {
      background: $offwhite;
      margin-bottom: 10px;
      padding: 20px;
      transition: background 0.1s;
      position: relative;
      display: none;
      border-radius: 3px;

      &.active {
        display: block;
      }

      &[data-type] {
        padding-top: 50px;

        .material-icons {
          position: absolute;
          color: $blue;
          top: 15px;
          left: 18px;
          font-size: 27px;
          letter-spacing: 0;
          line-height: 1;
        }
      }

      .location-name {
        font-weight: 500;
        font-size: 14px;
        margin: 0 0 10px 0;
        display: block;
        line-height: 1.4;

        &:hover {
          text-decoration: underline;
          color: $body-color;
        }

        @include media($wideDesk) {
          margin: 0 80px 12px 0;
        }
      }

      .location-terms {
        @extend .list-reset;
        font-weight: 300;
        font-size: 13px;
        line-height: 1.35;
      }

      .location-extra {
        font-weight: 300;
        font-size: 13px;
        line-height: 1.35;

        @include media($wideDesk) {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      }

      body.handheld & a.location-extra {
        font-weight: 500;
        color: $white;
        margin-top: 15px;
        max-width: 100%;
        background: $green;
        text-align: center;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 8px 20px;
        border-radius: 3px;
      }

      .location-distance {
        font-size: 13px;
        margin-left: 7px;

        &::before {
          content: '—';
          margin-right: 7px;
        }

        @include media($wideDesk) {
          position: absolute;
          bottom: 60px;
          right: 20px;
          font-weight: 500;

          &::before, &::after {
            content: '';
          }
        }
      }

      &:hover {
        background: darken($offwhite, 3%);
      }
    }
  }

  &__credits {
    text-align: center;
    margin: 20px 0 10px;

    p {
      margin: 0;

      @include media($mobileOnly) {
        font-size: 13px;
      }
    }

    a {
      border: none;
      display: inline-block;
      margin-top: 12px;

      img {
        display: block;
        width: 130px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &__to-top-wrapper {
    display: flex;
    justify-content: center;
  }

  &__to-top {
    margin: 20px 0 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    color: $grey;
    display: inline-block;

    i {
      font-size: 15px;
      padding-bottom: 5px;
    }
  }
}

.keyword-search {
  position: relative;
  flex-grow: 1;

  &__search {
    @extend .community-input;
    border: 1px solid $offwhite;
    background: $offwhite;
    vertical-align: middle;
    width: 100%;
    color: $body-color;
    @include placeholder($grey, 500, 1);

    &:focus, &:active {
      color: $blue-hover;
      border-color: $blue-hover;
      background: $white;
      @include placeholder($white, 500, 1);
    }
  }

  &__submit {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    color: $blue;
  }
}

.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  border-top: 0;
  background: #fff;
  box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.autocomplete-suggestion {
  position: relative;
  padding: 0 0.6em;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.02em;
  color: #333;
}

.autocomplete-suggestion b {
  font-weight: normal;
  color: $blue;
}

.autocomplete-suggestion.selected {
  background: $green;
}

.page-template-page-community-services {
  .autocomplete-suggestions {
    border-color: $blue-hover;
    border-radius: 0 0 3px 3px;

    .autocomplete-suggestion {
      color: $body-color;
      padding: {
        top: 3px;
        bottom: 3px;
      }
      font-size: 15px;

      @include media($tab) {
        font-size: 16px;
      }

      &.selected {
        background: rgba($blue, 0.25);
      }

      b {
        color: $blue-2;
        font-weight: 500;
      }
    }
  }

  footer {
    z-index: 1;
    display: none;

    &.active {
      display: block;
    }
  }
}

#tooltip-container {
  position: absolute;
  width: 400px;
  bottom: calc(50% + 50px);
  z-index: 10;

  @include media($tabAndLess) {
    top: 100%;
    left: 0;
    right: 0;
    bottom: auto;
    width: auto;
    display: none;
  }

  @include media($desk) {
    opacity: 0;
    pointer-events: none;
    transform-origin: 0% calc(100% + 20px);
    left: 50%;
    transform: scale(0.8) translate(-50%, 5px);
    box-shadow: 1px 2px 8px rgba($black, 0.3);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      pointer-events: none;
      background: linear-gradient(to top, $white 0%, $white 10%, rgba($white, 0) 100%);
    }
  }

  &.active {

    @include media($tabAndLess) {
      display: block;
    }

    @include media($desk) {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%);
      transition: opacity 0.2s, transform 0.2s;
    }
  }

  &.is-foundry {
    @include media($desk) {
      bottom: calc(50% + 15px);
    }
  }
}

.info-window-container {
  position: relative;
  background: $white;

  @include media($desk) {
    max-height: calc(100vh / 2 - 100px);
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: $offwhite;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $offwhite;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darken($offwhite-dark, 8%);
    }
  }
}

.info-window {
  background: $white;
  text-align: center;

  border-bottom: 1px solid lighten($offwhite-dark, 5%);

  @include media($tabAndLess) {
    &:first-of-type {
      .info-window__header {
        box-shadow: inset 0 3px 0 $blue;
      }
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__header {
    background: $white;
    padding: 30px 40px 10px;
    color: $blue;
    position: relative;

    #info-window-name {
      font-weight: 700;
      font-size: 15px;
      line-height: 1.4;
      margin-bottom: 5px;
      // color: $body-color;
    }

    #info-window-address {
      font-size: 13px;
      font-weight: 400;
      // color: $grey;
    }
  }

  &__header-bg {
    @extend .full-size;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0.15;
    display: none;
  }

  &__header-container {
    position: relative;
  }

  &__footer {
    padding: 20px;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: space-around;

    .info-window-col {
      display: none;
      flex-grow: 1;
      margin: 0 10px;
      color: $blue;

      &:hover {
        color: $blue-hover;
      }

      p {
        margin: 8px 0 0;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }

      &.active {
        display: block;
      }
    }
  }
}

#info-window-close {
  position: absolute;
  color: $grey;
  top: 10px;
  right: 10px;
  line-height: 1;
  cursor: pointer;

  i {
    font-size: 20px;
  }
}

.info-window-anchor {
  position: absolute;
  display: none;
  left: calc(50% - 10px);

  @include media($desk) {
    display: inline-block;
  }

  .left, .right {
    position: absolute;
    overflow: hidden;
    height: 30px;

    > div {
      position: absolute;
      background-color: $white;
      box-shadow: 0 2px 8px rgba($black, 0.4);
    }
  }

  .left {
    top: -1px;
    left: -16px;
    width: 26px;

    > div {
      left: 6px;
      transform: skewX(45deg);
      transform-origin: 0 -3px 0;
      height: 24px;
      width: 25px;
    }
  }

  .right {
    top: -1px;
    left: 10px;
    width: 16px;

    > div {
      left: 8px;
      transform: skewX(-45deg);
      transform-origin: 10px -11px 0;
      height: 24px;
      width: 20px;
    }
  }
}

