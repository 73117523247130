.fadeInUp {
  animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation: fadeInDown 0.5s ease;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeOutDown {
  animation: fadeOutDown 0.5s ease forwards;
}

@keyframes fadeOutDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  5% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

@keyframes leftToRight {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
    transform: translateY(-5px);
  }

  10%,
  90% {
    opacity: 1;
    transform: translateY(0);
  }
}
